<template>
    <DataTable
        ref="dt"
        :value="liste" :paginator="true" :rows="rows" 
        v-model:selection="dataSelected"
        v-model:filters="filters"
        stripedRows
        removableSort
        :paginatorTemplate="liste.length > 5 ? 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown' : ''"
        :rowsPerPageOptions="[5, 10, 25, 50, 100, 250]"
        :currentPageReportTemplate="$t('dt.datatable.currentPageReportTemplate')"
        :global-filter-fields="adFilter"
        :filterDisplay="adFilter ? 'menu' : null"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        selectionMode="single"
        @rowSelect="emit('dataSelected', dataSelected)"
        @rowUnselect="emit('dataSelected', null)"
        @update:rows="(v) => changeRows(v)"
        responsiveLayout="scroll"
    >
        <template #header>
            <div class="flex flex-col md:flex-row md:justify-between md:align-items-center">
                <div>
                    <Button v-if="export" icon="pi pi-external-link" text label="Export" severity="info" @click="exportCSV($event)" />
                </div>
                <div v-if="filter" class="flex flex-row gap-2">
                    <InputText v-model="filters['global'].value" :placeholder="$t('dt.datatable.search')" class="w-full"/>
                </div>
                <div class="flex flex-row gap-2">
                    <slot name="headerAction">
                    </slot>
                </div>
            </div>
        </template>
        <slot name="columns" :columns="columns">
            <Column v-for="col in columns" :field="col.field" :header="col.header" :hidden="col.hidden" :sortable="col.sortable" style="width: 250px;" :showFilterMatchModes="false" :showApplyButton="false">
                <template #body="slotProps">
                    <div class="dfrow gap-1 items-center">
                        <Cell :value="slotProps.data[col.field]" :type="fields[col.field]?.type" :options="{severity: col.filter?.severity}"/>
                        <Button v-if="col.copy" icon="pi pi-copy" v-clipboard="slotProps.data[col.field]" text v-tooltip="'Copier'"/>
                    </div>
                    <!-- <div v-if="col.copy" class="dfrow gap-1 items-center">
                        <span>{{ slotProps.data[col.field] }}</span>
                        <Button icon="pi pi-file-edit" v-clipboard="slotProps.data[col.field]" text v-tooltip="'Copier'"/>
                    </div> -->
                </template>
                <!-- Ça c'est pour appliquer des possible filtre aux colonnes... voilà... -->
                <template v-if="col.filter" #filter="{ filterModel, filterCallback }">
                    <Dropdown v-if="col.filter.name === 'dropdown'" @change="filterCallback" v-model="filterModel.value" :options="col.filter.options" placeholder="Filtrer status" style="min-width: 12rem;" showClear>
                        <template #option="slotProps">
                            <Tag :value="slotProps.option" :severity="col.filter.severity(slotProps.option)" />
                        </template>
                    </Dropdown>
                </template>
            </Column>
        </slot>
        <slot name="actionColumn">
            <Column headerStyle="min-width:10rem;">
                <template #body="slotProps">
                </template>
            </Column>
        </slot>
    </DataTable>
</template>

<script setup>
import { toRef } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { FilterMatchMode } from 'primevue/api'
import { onBeforeMount, ref, watch } from 'vue'
import Cell from '@/components/datatable/TableCell.vue'

const props = defineProps({
    liste: {
        type: Array,
        required: true
    },
    columns: {
        type: Array,
        required: true
    },
    dataSelected: {
        type: Object,
        default: null
    },
    filter: {
        type: Boolean,
        default: true
    },
    fields: {
        type: Object,
        required: true
    },
    export: {
        type: Boolean,
        default: false
    },
    tName: {
        type: String
    },
    reactiveRows: {
        type: Boolean,
        default: false
    },
    adFilter: {
        type: Array,
        default: null
    }
})


const route = useRoute()
const store= useStore()
const rows = ref(10)
const dt = ref(null)

const dataSelected = ref()
const isDataSelected = toRef(props, 'dataSelected')
const emit = defineEmits(['updateCell', 'dataSelected'])

const datatableName = route.name.replaceAll(/\s/g, '') + '.' + props.tName

onBeforeMount(() => {
    if(props.tName){
        const storedRowsForThisTable = store.getters['statePage/getDatatables'][datatableName]
        if(storedRowsForThisTable){
            rows.value = storedRowsForThisTable
        }
    }
    initFilters()
})

const filters = ref({})
const initFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    }
    props.adFilter?.forEach(filter => {
        const col = props.columns.find(x => x.field === filter)
        filters.value[filter] =  { value: null, matchMode: col.filter ? FilterMatchMode[col.filter.mode] : FilterMatchMode.CONTAINS}   
    })
}

const onCellEditComplete = (event) => {
    let { data, newValue, field } = event
    let row = props.liste.find(a => a.id === data.id)
    row[field] = newValue
    emit('updateCell', {id: row.id, field: field, value: newValue})
}

watch(isDataSelected, () => {
    if(!isDataSelected.value){
        dataSelected.value = null
    }
    else if(props.reactiveRows){
        dataSelected.value = props.liste.find(i => i.id === isDataSelected.value.id)
    }
})

const exportCSV = () => {
    dt.value.exportCSV()
}

function changeRows(newRows){
    if(props.tName){
        store.dispatch('statePage/changeDatatables', {value: newRows, key: datatableName})
    }
}
</script>