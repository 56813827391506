const initialState = {
    lang: {label: 'fr', fallback: 'fr'},
}

export const lang = {
    namespaced: true,
    state: initialState,
    getters: {
        getLang(state){
            return state.lang
        }
    },
    actions: {
        changeLang({commit}, lang){
            commit('changeLang', lang)
        }
    },
    mutations: {
        changeLang(state, lang){
            state.lang = lang
        }
    }
}