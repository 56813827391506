import global from './global.js';
import autocomplete from './autocomplete/index.js';
import calendar from './calendar';
// import cascadeselect from './cascadeselect';
import checkbox from './checkbox/index.js';
import chips from './chips/index.js';
// import colorpicker from './colorpicker';
import dropdown from './dropdown/index.js';
import inputgroup from './inputgroup/index.js';
import inputgroupaddon from './inputgroupaddon/index.js';
// import inputmask from './inputmask';
import inputnumber from './inputnumber/index.js';
import inputswitch from './inputswitch/index.js';
import inputtext from './inputtext/index.js';
import knob from './knob/index.js';
// import listbox from './listbox';
// import multiselect from './multiselect';
import password from './password/index.js';
// import radiobutton from './radiobutton';
// import rating from './rating';
// import selectbutton from './selectbutton';
import slider from './slider/index.js';
import textarea from './textarea/index.js';
import togglebutton from './togglebutton/index.js';
// import treeselect from './treeselect';
// import tristatecheckbox from './tristatecheckbox';
import button from './button/index.js';
// import speeddial from './speeddial';
// import splitbutton from './splitbutton';
import datatable from './datatable/index.js';
// import dataview from './dataview';
// import orderlist from './orderlist';
// import organizationchart from './organizationchart';
import paginator from './paginator/index.js';
// import picklist from './picklist';
// import tree from './tree';
import treetable from './treetable/index.js';
// import timeline from './timeline';
// import accordion from './accordion';
// import card from './card';
// import deferredcontent from './deferredcontent';
// import divider from './divider';
import fieldset from './fieldset/index.js';
// import panel from './panel';
// import scrollpanel from './scrollpanel';
import tabview from './tabview/index.js';
// import toolbar from './toolbar';
// import confirmpopup from './confirmpopup';
import dialog from './dialog/index.js';
import overlaypanel from './overlaypanel/index.js';
import sidebar from './sidebar/index.js';
import tooltip from './tooltip/index.js';
// import breadcrumb from './breadcrumb';
// import contextmenu from './contextmenu';
// import dock from './dock';
// import menu from './menu';
import menubar from './menubar/index.js';
// import megamenu from './megamenu';
// import panelmenu from './panelmenu';
import steps from './steps/index.js';
// import tabmenu from './tabmenu';
// import tieredmenu from './tieredmenu';
// import message from './message';
import inlinemessage from './inlinemessage/index.js';
import toast from './toast/index.js';
// import image from './image';
// import avatar from './avatar';
import badge from './badge/index.js';
import badgedirective from './badgedirective/index.js';
import blockui from './blockui/index.js';
import chip from './chip/index.js';
import scrolltop from './scrolltop/index.js';
import skeleton from './skeleton/index.js';
import progressbar from './progressbar/index.js';
import ripple from './ripple/index.js';
import tag from './tag/index.js';
import terminal from './terminal/index.js';
import floatlabel from './floatlabel/index.js';
// import splitter from './splitter';
// import splitterpanel from './splitterpanel';


export default {
    global,
    directives: {
        tooltip,
        badgedirective,
        ripple
    },
    global,
    floatlabel,
    // splitter,
    // splitterpanel,
    autocomplete,
    calendar,
    // cascadeselect,
    checkbox,
    chips,
    // colorpicker,
    dropdown,
    inputgroup,
    inputgroupaddon,
    // inputmask,
    inputnumber,
    inputswitch,
    inputtext,
    knob,
    // listbox,
    // multiselect,
    password,
    // radiobutton,
    // rating,
    // selectbutton,
    slider,
    textarea,
    togglebutton,
    // treeselect,
    // tristatecheckbox,
    button,
    // speeddial,
    // splitbutton,
    datatable,
    // dataview,
    // orderlist,
    // organizationchart,
    paginator,
    // picklist,
    // tree,
    treetable,
    // timeline,
    // accordion,
    // card,
    // deferredcontent,
    // divider,
    fieldset,
    // panel,
    // scrollpanel,
    tabview,
    // toolbar,
    // confirmpopup,
    dialog,
    overlaypanel,
    sidebar,
    // breadcrumb,
    // contextmenu,
    // dock,
    // menu,
    menubar,
    // megamenu,
    // panelmenu,
    steps,
    // tabmenu,
    // tieredmenu,
    // message,
    inlinemessage,
    toast,
    // image,
    // avatar,
    badge,
    blockui,
    chip,
    scrolltop,
    skeleton,
    progressbar,
    tag,
    terminal
}
