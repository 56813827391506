export default {
    root: ({context:e}) => ({
        class:
        [
            "font-sans leading-6",
            "sm:text-sm",
            "m-0",
            "py-1.5 px-3",
            "rounded-md",
            "appearance-none",
            "text-surface-900 dark:text-surface-0",
            "placeholder:text-surface-400 dark:placeholder:text-surface-500",
            "bg-surface-0 dark:bg-surface-900",
            "ring-1 ring-inset ring-surface-300 dark:ring-surface-700 ring-offset-0",
            "shadow-sm",
            {
                "outline-none focus:ring-primary-500 dark:focus:ring-primary-400" : !e.disabled,
                "opacity-60 select-none pointer-events-none cursor-default" : e.disabled
            },
            "appearance-none",
            "transition-colors duration-200"
        ]
    })
}

