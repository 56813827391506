const TRANSITIONS = {
    overlay: {
        enterFromClass: 'opacity-0 scale-75',
        enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
        leaveActiveClass: 'transition-opacity duration-150 ease-linear',
        leaveToClass: 'opacity-0'
    }
};

export const menu = {
    root: 'py-1 text-gray-700 dark:text-white/80',
    menu: {
        class: ['m-0 p-0 list-none', 'outline-none']
    },
    content: ({ context }) => ({
        class: [
            'text-gray-700 dark:text-white/80 transition-shadow duration-200 rounded-none',
            'hover:bg-gray-200 dark:hover:bg-zinc-900/80  hover:text-gray-900 dark:hover:text-white/80', // Hover
            {
                'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused
            }
        ]
    }),
    action: {
        class: ['text-gray-700 dark:text-white/80 py-3 px-5 select-none', 'cursor-pointer flex items-center no-underline overflow-hidden relative']
    },
    icon: 'text-gray-600 dark:text-white/70 mr-2',
    submenuheader: {
        class: [
            'm-0 p-3 text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 font-bold rounded-tl-none rounded-tr-none',
            'hover:bg-gray-200 dark:hover:bg-zinc-900/80  hover:text-gray-700 dark:hover:text-white/80'
        ]
    },
    transition: TRANSITIONS.overlay
}
