<template>
    <Image src="/logo-horiz-210x60.png" alt="Image" class="mb-20"/>
    <div class="h-full">
        <Dropdown v-model="selectedService" filter @change="changeServiceSelected($event)" :options="services" placeholder="Selectionnez un service"/>
        <PanelMenu v-if="selectedService && !loading" :model="allRoutes" :pt="panelMenu.panelmenu">
            <template #item="{ item }">
                <RouterLink v-if="item.path" :to="item.path" :title="item.label" class="flex cursor-pointer w-fit md:w-full text-color px-3 py-2 hover:bg-bleu/30 rounded-md">
                    <a class="dfrow items-center p-2 rounded">
                        <div>
                            <Image :src="getImage(item.icon)" alt="" width="30" height="25" />
                        </div>
                        <span class="ml-5">{{ item.label }}</span>
                    </a>
                </RouterLink>
                <a v-else class="flex cursor-pointer text-color px-3 py-2">
                    <div>
                        <Image :src="getImage(item.icon)" alt="" width="30" height="25" />
                    </div>
                    <span class="ml-2">{{ item.label }}</span>
                    <span v-if="item.items" class="pi pi-angle-down text-primary ml-auto" />
                </a>
            </template>
        </PanelMenu>
    </div>
    <div class="grid grid-cols-2 gap-5 pl-5">
        <div class="col-span-2">
            <ContactSupport/>
        </div>
        <div class="dfrow col-span-2 gap-5">
            <Button label="Deconnexion" @click="logout()" :pt="button.logoutButton" />
            <LoggedUser v-if="user" :user="user.info"/>
            <div class="flex items-end">
                <span class="text-gris/50">{{ version }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount,ref, watch } from 'vue'
import { routes } from '../router'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import { panelMenu } from '@/styles/over/styles'
import { useToast } from 'primevue/usetoast'
import { button } from '@/styles/over/styles.js'
import { useStore } from 'vuex'
import LoggedUser from '@/pages/LoggedUser.vue'
import ContactSupport from '@/pages/ContactSupport.vue'
import AuthService from "@/services/auth.service"
import ServicePing from '@/services/ping'
import { version } from '@/config'

const toast = useToast()
const store = useStore()
const router = useRouter()
const loading = ref(false)
const selectedService = ref()
const oldSelected = ref()
const user = ref(null)
var services = ref([])
const userGrants = computed(() => {
  return user.value?.info?.grants[selectedService.value]
})

const allRoutes = computed(() => {
    return routes.value
})

onBeforeMount(() => {
    const isLog = store.getters["auth/getLoggedInfo"]
    user.value = store.getters["auth/getUser"]
    selectedService.value = store.getters['auth/getServiceSelected']
    oldSelected.value = store.getters['auth/getServiceSelected']
    if(isLog && user.value.info?.grants) {
        services.value = store.getters['auth/getServices']
    }
})

watch(() => store.getters['auth/getServiceSelected'], async function() {
    if(store.getters['auth/getServiceSelected']){
        selectedService.value = store.getters['auth/getServiceSelected']
        loading.value = true
        await nextTick()
        loading.value = false
    }

})

watch(() => store.getters['auth/getServices'], () => {
    services.value = store.getters['auth/getServices']
})

watch(userGrants, async() => {
    if(user.value?.infos){
        loading.value = true
        await nextTick()
        loading.value = false
    }
})

const logout = () => {
    AuthService.logout();
    store.dispatch('auth/logout')
}

function getImage(icon) {
    return require('@/assets/icon/' + icon)
}

async function changeServiceSelected(event){
    loading.value = true
    ServicePing.ping(event.value)
    .then((response) => {
        store.dispatch('auth/changeServiceSelected', event.value).then(
            () => {
                toast.add({
                    summary: "Choix du service modifié",
                    severity: "success",
                    detail: "Le service selectionné est "+event.value,
                    life: 4000
                })
                router.push('/')
                oldSelected.value = event.value
                loading.value = false
            },
            _error => {
                // console.log(_error)
                toast.add({
                    summary: "Erreur",
                    severity: "error",
                    detail: "Impossible de modifier le choix du service",
                    life: 4000
                })
            }
        )
    })
    .catch(async() => {
        selectedService.value = oldSelected.value
        changeServiceSelected(oldSelected)
        toast.add({
            summary: "Erreur",
            severity: "error",
            detail: "Impossible de modifier le choix du service",
            life: 4000
        })
    })
}
</script>