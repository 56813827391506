<template>
  <div class="dfrow gap-3 stickyBtns z-40">
    <button 
      v-if="route.name === 'Dashboard' && !serviceSelected"
      type="button" v-tooltip="'Se déconnecter'"
      class="btnsInSticky"
      @click="logout"
    >
      <i class="pi pi-sign-out"/>
    </button>
    <button 
      v-if="bool && route.fullPath !== '/admin'"
      type="button" v-tooltip="'Page administration'"
      class="btnsInSticky"
      @click="admin"
    >
      <Image :src="getImage()" alt="" width="18" height="15" />
    </button>
    <button 
      v-if="!serviceSelected && route.fullPath === '/admin'"
      type="button" v-tooltip="'Sélection des services'"
      class="btnsInSticky"
      @click="admin"
    >
      <span class="pi pi-arrow-left"></span>
    </button>
    <button
      type="button" v-tooltip="'Activer/désactiver mode sombre'"
      class="btnsInSticky"
      @click="toggleDarkMode"
    >
      <i class="pi toggle-icon" />
    </button>
    <button 
      v-if="winWidth < 768"
      type="button" v-tooltip="'Menu'"
      class="btnsInSticky"
      @click="visible = true"
    >
      <i class="pi pi-bars" />
    </button>
  </div>
  <Suspense>
    <div class="h-screen">
      <div v-if="route.name !== 'Login' && serviceSelected && userInfos" class="card dfrow">
        <Transition name="slide-fade">
          <div class="navmenu" v-if="!loading && screenWd && userInfos">
              <NavMenu/>
          </div>
        </Transition>
        <div class="card dfcol w-full md:basis-5/6 h-screen p-10 gap-9 over overflow-y-auto">
          <span v-if="serviceSelected" class="text-4xl font-extrabold inline-block align-text-bottom">{{ route.name }}</span>
          <div>
            <RouterView/>
          </div>
        </div>
      </div>
      <div v-else :class="(route.fullPath === '/admin' || route.fullPath === '/')?'py-20':''">
        <RouterView />
      </div>
    </div>
  </Suspense>
  <Suspense>
    <DynamicDialog/>
  </Suspense>
  <Toast position="bottom-right"/>
  <Sidebar v-model:visible="visible" :showCloseIcon="false">
    <div class="navmenu">
      <NavMenu/>
    </div>
  </Sidebar>
</template>


<script setup>
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { toast } from '@/styles/over/styles'
import NavMenu from './components/NavMenu.vue'
import EventBus from "@/common/EventBus"
import AuthService from "@/services/auth.service"

const winWidth = ref(window.innerWidth)

const visible = ref(false)
const loading = ref(false)
const route = useRoute()
const router = useRouter()
const store = useStore()
const serviceSelected = ref()

const user = ref(store.getters['auth/getUser'])
const userInfos = computed(() => {
  return user.value?.info
})

const bool = computed(() => {
  if(user.value){
    return user.value?.info?.is_superuser 
  }
  return false
})

const screenWd = computed(() => {
  if(winWidth.value >= 768){
    return true
  }
  return false
})

onBeforeMount(async() => {
  if(store.getters['index/getDarkmode']){
    document.documentElement.classList.toggle('dark')
  }
  if(store.getters['auth/getUser']){
    AuthService.userInfos()
  }
  window.addEventListener('resize', () => {
    winWidth.value = window.innerWidth
    if(winWidth.value >= 768 && visible.value){
      visible.value = false
    }
  })
})

onMounted(()=>{
  EventBus.on("logout", () => {
    this.logOut()
  });
  serviceSelected.value = store.getters['auth/getServiceSelected']
  if(user.value?.info.is_superuser) {
    bool.value = true
  }
})

watch(() => store.getters['auth/getServiceSelected'], function() {
  if(store.getters['auth/getServiceSelected']){
    serviceSelected.value = store.getters['auth/getServiceSelected']
  }
})

watch(() => store.getters['auth/getUser'], () => {
  user.value = store.getters['auth/getUser']
})

onBeforeUnmount(()=> {
  EventBus.remove("logout")
})

const toggleDarkMode = () => {
  document.documentElement.classList.toggle('dark')
  store.dispatch('index/changeDarkmode')
}

const logout = () => {
    AuthService.logout();
    store.dispatch('auth/logout');
}

const admin = () => {
  if(route.fullPath === '/admin'){
    router.push('/')
  }
  else {
    router.push('/admin')
  }
}

function getImage() {
    return require('@/assets/icon/superadmin.svg')
}

</script>


<style>
.toggle-icon:before {
    content: "\e9c8";
}

:root[class="dark"] .toggle-icon:before {
    content: "\e9c7";
}


.slide-fade-enter-active {
  transition: all 1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(10, 5, 8, 10);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>