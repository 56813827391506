<template>
    <div v-if="LOADING === 'ERROR'">
        <span>{{ $t('dt.unavailable') }}</span>
    </div>
    <div v-else-if="(!LOADING && email) || RELOADTIME" class="dfcol gap-5 mt-12">
        <CustomDatatable :liste="LISTE_DOMAINS" :columns="email.columns.domains" @dataSelected="chosenDomain" :dataSelected="selectedDomain" :fields="DOMAIN_FIELDS" export tName="domains" reactiveRows>
            <template #headerAction>
                <Button :label="$t('dt.add')" text @click="visibleAddDomain = true"/>
            </template>
            <template #actionColumn>
                <Column headerStyle="min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex flex-row-reverse">
                            <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="delDomain(slotProps.data)"/>
                            <Button :label="$t('emailVue.tabAdd.redir')" text severity="info" @click="{selectedDomain = slotProps.data; visibleAddRedir = true}"/>
                            <Button :label="$t('emailVue.tabAdd.mail')" text severity="info" @click="{selectedDomain = slotProps.data; visibleAddMail = true}"/>
                        </div>
                    </template>
                </Column>
            </template>
        </CustomDatatable>
        <div class="dfrow gap-1 items-center w-fit">
            <h1 class="text-xl">
                 {{ $t('emailVue.manage') }}
            </h1>
            <Dropdown v-model="selectedDomain" :options="LISTE_DOMAINS" option-label="domain"/>
        </div>
        <div v-if="selectedDomain" class="flex flex-col md:flex-row gap-3">
            <div>
                <span>Liste des adresses</span>
                <CustomDatatable :liste="LISTE_MAILS[selectedDomain.domain]" :columns="email.columns.addresses" @dataSelected="editMail" :dataSelected="inEditObj" :fields="MAILS_FIELDS" export tName="emails">
                    <template #headerAction>
                        <Button :label="$t('dt.add')" text @click="visibleAddMail = true"/>
                    </template>
                    <template #actionColumn>
                        <Column headerStyle="min-width:2rem;">
                            <template #body="slotProps">
                                <div class="flex flex-row-reverse">
                                    <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="delMail(slotProps.data)"/>
                                    <Button icon="pi pi-file-edit" text severity="info" v-tooltip="$t('dt.edit')" @click="editMail(slotProps.data)"/>
                                </div>
                            </template>
                        </Column>
                    </template>
                </CustomDatatable>
            </div>
            <div>
                <span>Liste des redirections</span>
                <CustomDatatable :liste="LISTE_REDIR[selectedDomain.domain]" :columns="email.columns.redirections" @dataSelected="editRedir" :dataSelected="inEditObj" :fields="REDIR_FIELDS" export tName="redirects">
                    <template #headerAction>
                        <Button :label="$t('dt.add')" text @click="visibleAddRedir = true"/>
                    </template>
                    <template #actionColumn>
                        <Column headerStyle="min-width:2rem;">
                            <template #body="slotProps">
                                <div class="flex flex-row-reverse">
                                    <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="delRedir(slotProps.data)"/>
                                    <Button icon="pi pi-file-edit" text severity="info" v-tooltip="$t('dt.edit')" @click="editRedir(slotProps.data)"/>
                                </div>
                            </template>
                        </Column>
                    </template>
                </CustomDatatable>
            </div>
        </div>
    </div>
    <div v-else class="flex flex-col items-center md:h-screen lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>

    <!-- CRÉER UN DOMAINE -->
    <Dialog v-model:visible="visibleAddDomain" :header="$t('emailVue.addDomain')" modal>
        <Form
            @submit="(v) => addDomain(v)"
            id="formAddDomain"
            :validation-schema="email.schemas.addDomain"
            @invalid-submit="(v) => console.log(v)"
            class="dfcol gap-3 overflow-x-hidden p-3"            
        >
            <div class="flex justify-center w-full">
                <Field :field="email.fields.domains.domain"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAddDomain"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAddDomain = false"/>
        </template>
    </Dialog>

    <!-- CRÉER UN UNE ADRESSE MAIL -->
    <Dialog v-model:visible="visibleAddMail" :header="$t('emailVue.addMail')" modal @after-hide="resetFields">
        <Form
            @submit="(v) => addMailBox(v)"
            id="formAddMail"
            :validation-schema="email.schemas.addMail"
            @invalid-submit="(v) => console.log(v)"
            class="dfcol gap-3 overflow-x-hidden p-3"            
        >
            <div class="dfrow w-max">
                <Field :field="email.fields.addresses.mail"/>
                <div class="flex items-end select-none">
                    <Badge :value="'@' + selectedDomain.domain" severity="info" v-tooltip="$t('emailVue.badge.addMail')"></Badge>
                </div>
            </div>
            <div class="flex flex-col md:flex-row justify-between gap-3">
                <div class="dfcol gap-1">
                    <Field :field="email.fields.addresses.name"/>
                    <Field :field="email.fields.addresses.quota"/>                
                </div>
                <div class="dfcol gap-6">
                    <Field :field="email.fields.addresses.password" :value="password" @updateValue="(v, gen) => passGen(v, gen)"/>
                    <Field :field="email.fields.addresses.passwordCheck" :value="passwordCheck"/>                
                </div>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAddMail"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAddMail = false"/>
        </template>
    </Dialog>

    <!-- CRÉER UNE REDIRECTION -->
    <Dialog v-model:visible="visibleAddRedir" :header="$t('emailVue.addRedir') + ' ' + selectedDomain?.domain" modal>
        <Form
            @submit="(v) => addRedir(v)"
            id="formAddRedir"
            :validation-schema="email.schemas.addRedir"
            @invalid-submit="(v) => console.log(v)"
            class="dfcol gap-3 overflow-x-hidden p-3"            
        >
            <div class="flex flex-col md:flex-row">
                <Field :field="email.fields.redirections.mail"/>
                <Field :field="email.fields.redirections.redir"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAddRedir"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAddRedir = false"/>
        </template>
    </Dialog>

    <!-- MODIFIER UNE ADRESSE -->
    <Dialog v-model:visible="visibleEditMail" modal :header="$t('emailVue.editMail')" @after-hide="resetFields">
        <Form
            @submit="(v) => editMailBox(v)"
            id="formEditMail"
            :validation-schema="email.schemas.editMail"
            @invalid-submit="(v) => console.log(v)"
            class="dfrow pb-3 justify-between gap-3"
        >
            <div class="dfcol gap-3">
                <Field :field="email.fields.addresses.name" :value="inEditObj.name"/>
                <Field :field="email.fields.addresses.quota" :value="inEditObj.quota"/>
            </div>
            <div class="dfcol gap-8">
                <Field :field="email.fields.addresses.newPassword" :value="newPassword" @updateValue="(v, gen) => newPassGen(v, gen)"/>
                <Field :field="email.fields.addresses.newPasswordCheck" :value="newPasswordCheck"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.edit')" severity="info" type="submit" form="formEditMail"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleEditMail = false"/>
        </template>  
    </Dialog>
    <!-- MODIFIER UNE REDIRECTION -->
    <Dialog v-model:visible="visibleEditRedir" modal :header="$t('emailVue.editRedir')" @after-hide="resetFields">
        <Form
            @submit="(v) => editRedirect(v)"
            id="formEditRedir"
            :validation-schema="email.schemas.editRedir"
            @invalid-submit="(v) => console.log(v)"
            class="dfrow pb-3 justify-between gap-3"
        >
            <div class="dfrow justify-between w-full">
                <Field :field="email.fields.redirections.mail" :value="inEditObj.address" disabled/>
                <Field :field="email.fields.redirections.redir" :value="inEditObj.goto"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.edit')" severity="info" type="submit" form="formEditRedir"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleEditRedir = false"/>
        </template>  
    </Dialog>

    <!-- SUPPRIMER UN DOMAINE -->
    <Dialog v-model:visible="visibleDeleteDomain" :header="$t('emailVue.deleteDomain')" modal>
        <div class="pb-3">
            <span>{{ $t('emailVue.confirmDeleteDomain') }} <b>{{ inEditObj.domain }}</b> ?</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteDomain"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDeleteDomain = false"/>
        </template> 
    </Dialog>

    <!-- SUPPRIMER UNE ADRESSE MAIL -->
    <Dialog v-model:visible="visibleDeleteMail" :header="$t('emailVue.deleteMail')" modal>
        <div class="pb-3">
            <span>{{ $t('emailVue.confirmDeleteMail') }} <b>{{ inEditObj.username }}</b> ?</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteMailBox"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDeleteMail = false"/>
        </template> 
    </Dialog>

    <!-- SUPPRIMER UNE REDIRECTION -->
    <Dialog v-model:visible="visibleDeleteRedir" :header="$t('emailVue.deleteRedir')" modal>
        <div class="pb-3">
            <span>{{ $t('emailVue.confirmDeleteRedir') }} <b>{{ inEditObj.address }}</b> ?</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteRedir"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDeleteRedir = false"/>
        </template> 
    </Dialog>
</template>

<script setup>
import { ref, onBeforeMount, nextTick, watch } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'

let email = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/email')
    email = new response.default()
    await awaitAllOFThem()
}

/* Récupération des informations en base avec un chargement*/

/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
 const RELOADTIME = ref(false)

const LISTE_DOMAINS = ref([])
const LISTE_MAILS = ref({})
const LISTE_REDIR = ref({})
let DOMAIN_FIELDS = null
let MAILS_FIELDS = null
let REDIR_FIELDS = null

async function getListe(){
    LOADING.value = email.emailText.load.domains
    DOMAIN_FIELDS = email.fields.domains
    MAILS_FIELDS = email.fields.addresses
    REDIR_FIELDS = email.fields.redirections
    const res = await email.getListe()
    domainArray(Object.entries(res))
    // RELOADTIME.value ? null : email.getToast('info', '', email.mToast.getDomains.success)
}

function domainArray(array){
    const liste = []
    array.forEach((domain, i) => {
        LISTE_MAILS.value[domain[0]] = domain[1].address
        LISTE_REDIR.value[domain[0]] = domain[1].redirections
        liste.push({
            id: i,
            domain: domain[0],
            address: LISTE_MAILS.value[domain[0]].length,
            redirections: LISTE_REDIR.value[domain[0]].length
        })
    })
    LISTE_DOMAINS.value = [...liste]
}

async function awaitAllOFThem(){
    try {
        await getListe()
        LOADING.value = null
    } catch (error) {
        email.getToast('error', '', email.mToast.getDomains.fail)
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */

/* Affichage des deux autres tables lors de la sélection d'un domaine */
const selectedDomain = ref(null)

watch(selectedDomain, () => {
    console.log(selectedDomain.value)
})

async function chosenDomain(data){
    await nextTick()
    selectedDomain.value = data
}
/* ------------------------------------------------------------------ */

/* Champs et logique pour le formulaire de CRÉATION D'UN VHOST */
const password = ref(null)
const passwordCheck = ref(null)
const newPassword = ref("")
const newPasswordCheck = ref("")

function passGen(pass, gen){
    password.value = pass
    if(gen){
        passwordCheck.value = pass
    }
}
function newPassGen(pass, gen){
    newPassword.value = pass
    if(gen){
        newPasswordCheck.value = pass
    }
}

function resetFields(){
    inEditObj.value = null
    password.value = null
    passwordCheck.value = null
    newPassword.value = ""
    newPasswordCheck.value = ""
}
/* ----------------------------------------------------------- */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAddDomain = ref(false)
const visibleAddMail = ref(false)
const visibleAddRedir = ref(false)

const visibleEditMail = ref(false)
const visibleEditRedir = ref(false)

const visibleDeleteDomain = ref(false)
const visibleDeleteMail = ref(false)
const visibleDeleteRedir = ref(false)

function editMail(data){
    visibleEditMail.value = true
    inEditObj.value = {...data}
}

function editRedir(data){
    visibleEditRedir.value = true
    inEditObj.value = {...data}
}

function delDomain(data){
    selectedDomain.value = {...data}
    visibleDeleteDomain.value = true
    inEditObj.value = {...data}
}

function delMail(data){
    visibleDeleteMail.value = true
    inEditObj.value = {...data}
}

function delRedir(data){
    visibleDeleteRedir.value = true
    inEditObj.value = {...data}
}

/* ------------------------------------------------------------------------------ */


/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

function addDomain(values){
    RELOADTIME.value = true
    email.addDomain(values)
    .then(async(response) => {
        email.getToast('info', '', email.mToast.addDomain.success)
        await reloadTime(response.data.id)
        visibleAddDomain.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.addDomain.fail)
    })
}
function addMailBox(values){
    const data = {...values}
    data.domain = selectedDomain.value.domain
    data.mail += `@${data.domain}`
    RELOADTIME.value = true
    email.addMailBox(data)
    .then(async() => {
        email.getToast('info', '', email.mToast.addDomain.success)
        await reloadTime(selectedDomain.value.id)
        visibleAddMail.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.addDomain.fail)
    })
}

function addRedir(values){
    const data = {...values}
    data.domain = selectedDomain.value.domain
    data.mail += `@${data.domain}`
    RELOADTIME.value = true
    email.addRedirection(data)
    .then(async() => {
        email.getToast('info', '', email.mToast.addRedir.success)
        await reloadTime(selectedDomain.value.id)
        visibleAddRedir.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.addRedir.fail)
    })
}

function editMailBox(values){
    const data = {...values}
    data.domain = selectedDomain.value.domain
    data.mail = inEditObj.value.username
    if(values.newPassword){
        data.password = values.newPassword
        data.passwordCheck = values.newPasswordCheck
    }
    delete data.newPassword
    delete data.newPasswordCheck
    RELOADTIME.value = true
    email.editMailBox(data)
    .then(async() => {
        email.getToast('info', '', email.mToast.editMail.success)
        await reloadTime(selectedDomain.value.id)
        visibleEditMail.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.editMail.fail)
    })
}

function editRedirect(values){
    const data = {...values}
    data.domain = selectedDomain.value.domain
    RELOADTIME.value = true
    console.log(data)
    email.editRedir(data)
    .then(async() => {
        email.getToast('info', '', email.mToast.editRedir.success)
        await reloadTime(selectedDomain.value.id)
        visibleEditRedir.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.editRedir.fail)
    })
}

function deleteDomain(){
    RELOADTIME.value = true
    email.deleteDomain(inEditObj.value)
    .then(async() => {
        email.getToast('info', '', email.mToast.delDomain.success)
        await reloadTime(selectedDomain.value.id)
        visibleDeleteDomain.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.delDomain.fail)
    })    
}
function deleteMailBox(){
    const data = {...inEditObj.value}
    data.domain = selectedDomain.value.domain
    data.mail = inEditObj.value.username
    delete data.username
    RELOADTIME.value = true
    email.deleteMailBox(data)
    .then(async() => {
        email.getToast('info', '', email.mToast.delMail.success)
        await reloadTime(selectedDomain.value.id)
        visibleDeleteMail.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.delMail.fail)
    })    
}
function deleteRedir(){
    const data = {...inEditObj.value}
    data.domain = selectedDomain.value
    data.mail = inEditObj.value.address
    delete data.addresse
    RELOADTIME.value = true
    email.deleteRedirection(data)
    .then(async() => {
        email.getToast('info', '', email.mToast.delRedir.success)
        await reloadTime(selectedDomain.value.id)
        visibleDeleteRedir.value = false
    })
    .catch(async() => {
        email.getToast('error', '', email.mToast.delRedir.fail)
    })    
}

async function reloadTime(idDomain){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
    const newDomain = LISTE_DOMAINS.value.find(d => d.id === idDomain)
    selectedDomain.value = newDomain
}
/* -------------------------------------------------------------- */
</script>