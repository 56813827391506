<template>
    <div class="dfcol gap-1 w-full">
        <div class="dfcol">
            <label :for="field.id" class="text-sm indent-1">{{ field.label ? (field.label) : '' }} </label>
            <div class="dfrow gap-1">
                <component ref="refInput" v-if="fieldTypeVerif(field.type) === 0" :is="field.type" v-model="inputValue" v-bind="field.attrs" :disabled="disabled" @focus="isFocused = true" @blur="(v) => {handleBlur(v), isFocused = false}" @input="handleChange"/>
                <component ref="refInput" v-if="fieldTypeVerif(field.type) === 1" :is="field.type" v-model="inputValue" v-bind="field.attrs" :disabled="disabled" @focus="isFocused = true" @blur="isFocused = false" @input="(v) => handleChange(v.value)"/>
                <component ref="refInput" v-if="fieldTypeVerif(field.type) === 2" :is="field.type" v-model="inputValue" v-bind="field.attrs" :disabled="disabled" @focus="isFocused = true" @blur="isFocused = false" @change="(v) => handleChange(v.value)"/>
                <component ref="refInput" v-if="fieldTypeVerif(field.type) === 3" :is="field.type" v-model="inputValue" v-bind="field.attrs" :disabled="disabled" @focus="isFocused = true" @blur="(v) => {handleBlur(v), isFocused = false}" @input="handleChange" @complete="search"/>
                <component ref="refInput" v-if="fieldTypeVerif(field.type) === 4" :is="field.type" v-model="inputValue" v-bind="field.attrs" :disabled="disabled" @focus="isFocused = true" @blur="(v) => {handleBlur(v), isFocused = false}"/>
                <component ref="refInput" v-if="fieldTypeVerif(field.type) === 6" :is="'InputText'" v-model="inputValue" v-bind="field.attrs" :disabled="disabled" @focus="isFocused = true" @blur="(v) => {handleBlur(v), isFocused = false}"/>
                <ButtonGroup v-if="checkIfPasswordField(field) || field.attrs.genregex" class="dfrow">
                    <Button icon="pi pi-replay" outlined v-tooltip="$t('dt.randPass')" @click="generateRandomPass"/>
                    <Button icon="pi pi-file-edit" outlined v-tooltip="$t('dt.copy')" v-clipboard:copy="inputValue"/>
                </ButtonGroup>
            </div>
        </div>
        <p v-show="errorMessage">
            <InlineMessage v-if="errorMessage" severity="error">{{ errorMessage }}</InlineMessage>
        </p>
    </div>
</template>

<script setup>
import { ref, toRef, watch } from 'vue'
import { useField } from 'vee-validate'
import RandExp from 'randexp'

const props = defineProps({
    field: {
        type: Object,
        required: true
    },
    value: {
        default: null,
    },
    options: {
        type: Object,
        required: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const field = toRef(props, 'field')
const emit = defineEmits(['updateValue'])
const interceptedValue = toRef(props, 'value')

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(field.value.name, undefined, {
  initialValue: props.value,
})

watch(interceptedValue, () => {
    if(interceptedValue.value !== inputValue.value){
        inputValue.value = interceptedValue.value
    }
})

watch(inputValue, () => {
    if(generated){
        emit('updateValue', inputValue.value, true)
        generated = false
    }else{
        emit('updateValue', inputValue.value)
    }
})

if(field.value.type === 'InputSwitch') {
    if(typeof inputValue.value !== 'boolean'){
        inputValue.value = false
        emit('updateValue', inputValue.value)
    }
}

//Certains composants Primevue n'agissent pas de la même manière, par exemple le @input sur un InputText va renvoyer la valeur net
//alors que sur un InputNumber elle va renvoyer l'event en +
//Cette partie a des risques de changer au cours d'un développement selon les besoins
function fieldTypeVerif(type){
    switch (type) {
        case 'Calendar':
        case 'Password':
        case 'InputText':
        case 'Textarea':
        case 'Slider':
            return 0
        case 'InputNumber':
            return 1
        case 'MultiSelect':
        case 'Dropdown':
            return 2
        case 'AutoComplete':
            return 3
        case 'InputSwitch':
            return 4
        case 'Chips':
            return 5
        case 'Chip':
            return 6
    }
}

function checkIfPasswordField(field){
    return !!(field.type === 'Password' && !field.name?.includes('Check'));
}

const search = (event) => {
    field.value.suggestions = props.options.liste.filter((item) => item.slug.includes(event.query))
}

let generated = false

const generateRandomPass = async() => {
    generated = true
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!%*?\.])[A-Za-z\d@!%*?\.]{16}$/
    if(field.value.attrs.genregex){
        regex = field.value.attrs.genregex
        inputValue.value = new RandExp(regex).gen()
    }else{
        do {
            inputValue.value = new RandExp(regex).gen()
        } while (!regex.test(inputValue.value))
    }
}

//Fonctionnement pour disable un input lors de modification/chargement
const refInput = ref(null)
const isFocused = ref(false)
const isBeingDisabled = ref(false)
const isBeingFocused = ref(false)

watch(() => props.disabled, () => {
    if(props.disabled){
        isBeingDisabled.value = true
    }
    setTimeout(() => {
        if(!props.disabled && isBeingDisabled.value && isBeingFocused.value){
            refInput.value.$el.focus()
            isBeingDisabled.value = false
            isBeingFocused.value = false
        }
    }, 10)
})

watch(isFocused, () => {
    if(!props.disabled){
        isBeingFocused.value = isFocused.value
    }
})

</script>