
const initialState = {
  darkmode: {},
  collapse: true,
}

export const index = {
  namespaced: true,
  state: initialState,
  getters : {
    getDarkmode(state) {
      return state.darkmode
    },
    getCollapse(state) {
      return state.collapse
    }
  },
  actions: {
    changeDarkmode({ commit }){
      commit('changeDarkmode');
    },
    changeCollapse({ commit }){
      commit('changeCollapse');
    }
  },
  mutations: {
    changeDarkmode(state){
      state.darkmode = !state.darkmode
    },
    changeCollapse(state){
      state.collapse = !state.collapse
    }
  }
};


export default index