<template>
    <section>
        <h2>Page introuvable ...<br>
        Retrouvez votre chemin <a href="/">ici</a></h2>
    </section>
</template>

<script>
export default {
    name: "NotFound",
}
</script>
