<template>
    <Button icon="pi pi-megaphone" outlined :pt="button.userEdit" @click="visible = true" v-tooltip="'Contacter le support'"/>
    <Dialog v-model:visible="visible" modal header="Contacter le support">
        <div class="dfcol px-10 pb-10 gap-5 justify-center">
            <form @submit.prevent="onSubmit" class="dfcol gap-5">
                <span>
                    <InputText
                        id="email"
                        v-model="email"
                        v-bind="emailAttrs"
                        :class="{ 'p-invalid': errors.email }" 
                        aria-describedby="email-error" 
                        placeholder="Email" 
                        :pt="inputs.inputTextBase"
                    >
                    </InputText>
                </span>
                <InlineMessage v-if="errors.email" class="p-error" id="text-error" severity="error">{{ errors.email }}</InlineMessage>
                <span>
                    <Textarea
                        id="message"
                        v-model="message"
                        v-bind="messageAttrs"
                        :class="{ 'p-invalid': errors.message }" 
                        aria-describedby="message-error" 
                        placeholder="Message" rows="8" 
                        :pt="inputs.inputTextBase"
                    >
                    </Textarea>
                </span>
                <InlineMessage v-if="errors.message" class="p-error" id="text-error" severity="error">{{ errors.message }}</InlineMessage>
                <div class="flex items-center justify-end gap-3">
                    <Button label="Annuler" severity="danger" class="text-blanc" :pt="{label:'text-blanc font-semibold'}" @click="visible = false"/>
                    <Button :label="'Envoyer le message'" type="submit" :pt="button.logoutButton"/>
                </div>
            </form>
        </div>
    </Dialog>
</template>

<script setup>

import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { onBeforeMount, ref, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { button, inputs } from '@/styles/over/styles.js'
import ServiceTicket from '@/services/ticket'
import * as Yup from 'yup'

const visible = ref(false)
const store = useStore()
const toast = useToast()

const { errors, handleSubmit, defineField, resetForm } = useForm({
    validationSchema: Yup.object({
        email: Yup.string().email().required("L'email est requise pour envoyer un message au support."),
        message: Yup.string().required("Veuillez indiquer la raison de votre contact.")
    })
})

const [email, emailAttrs] = defineField('email')
const [message, messageAttrs] = defineField('message')

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit)

function onSuccess(values){
    ServiceTicket.sendTicket({mel: values.email, text: values.message})
    .then((response) => {
        resetForm()
        email.value = store.getters['auth/getUser'].info.email
    })
    .catch(() => {
        toast.add({ 
            severity: 'error', 
            summary: null,
            detail: 'Erreur API',
            life: 4000 
        });
    })
}
function onInvalidSubmit(values){
    console.log('Invalide')
}

watch(() => visible.value, () => {
    if(store.getters['auth/getUser']){
        if(store.getters['auth/getUser'].info.email){
            email.value = store.getters['auth/getUser'].info.email
        }
    }
})
</script>