<template>
    <div>
        <TabView v-model:activeIndex="active">
            <TabPanel :header="$t('icoaVue.tabUser')">
                <Users v-if="active === 0"/>
            </TabPanel>
            <TabPanel :header="$t('icoaVue.tabServ')">
                <Services v-if="active === 1"/>
            </TabPanel>
            <TabPanel :header="$t('icoaVue.tabTypes')">
                <Types v-if="active === 2" />
            </TabPanel>
            <TabPanel :header="$t('icoaVue.tabStatus')">
                <Status v-if="active === 3" />
            </TabPanel>
            <TabPanel :header="$t('icoaVue.tabLogs')">
                <Logs v-if="active === 4" />
            </TabPanel>
        </TabView>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { onBeforeMount, ref, watch } from 'vue'
import Users from './Users.vue'
import Services from './Services.vue'
import Types from './Types.vue'
import Status from './ServiceState.vue'
import Logs from './Logs.vue'

const store = useStore()
const active = ref(0)

onBeforeMount(() => {
    const activeTab = store.getters['statePage/getTabviews'].adminTabview
    if(activeTab){
        active.value = activeTab
    }
})

//Permet de garder en mémoire le dernier tab visionné pour le réafficher la prochaine fois
watch(active, () => {
    store.dispatch('statePage/changeTabviews', {value: active.value, key: 'adminTabview'})
})
</script>