export default {
    root: {
        class: ["p-5", "rounded-md", "bg-surface-900 text-white", "border border-surface-700", "h-72 overflow-auto"]
    },
    container: {
        class: ["flex items-center"]
    },
    prompt: {
        class: ["text-surface-400"]
    },
    response: {
        class: ["text-primary-400"]
    },
    command: {
        class: ["text-primary-400"]
    },
    commandtext: {
        class: ["flex-1 shrink grow-0", "border-0", "p-0", "bg-transparent text-inherit", "outline-none"]
    }
}
