<template>
    <div v-if="(!LOADING && user && !SHOWGRANTS) || RELOADTIME">
        <CustomDatatable :liste="LISTE" :columns="user.columns" @dataSelected="(v)=>goToGrants(v)" :dataSelected="inEditObj" :fields="user.fields.add" tName="icoUsers" reactiveRows>
            <template #headerAction>
                <Button :label="$t('dt.add')" text @click="visibleAdd = true"/>
            </template>
            <template #actionColumn>
                <Column headerStyle="min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex flex-row-reverse">
                            <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDelete(slotProps.data)"/>
                            <Button icon="pi pi-file-edit" text severity="info" v-tooltip="$t('dt.edit')" @click="goToEdit(slotProps.data, true)"/>
                        </div>
                    </template>
                </Column>
            </template>
        </CustomDatatable>
    </div>
    <div v-else-if="SHOWGRANTS" >
        <div class="dfrow gap-3 items-center">
            <Button icon="pi pi-arrow-left" @click="SHOWGRANTS = !SHOWGRANTS" text v-tooltip="$t('icoaVue.grants.back')"/>
            <h1 class="text-xl">{{ $tic('icoaVue.grants.user', {user: inEditObj.email}) }} (<b>{{ inEditObj.username }}</b>) </h1>
        </div>
        <Grants :data="inEditObj" :services="LISTE_SERVICES" />
    </div>
    <div v-else-if="!RELOADTIME" class="flex flex-col items-center lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>
    <!-- CRÉER UN UTILISATEUR  -->
    <Dialog v-model:visible="visibleAdd" modal :header="$t('icoaVue.addUser')" @after-hide="resetFields">
        <Form
            @submit="(v) => addUser(v)"
            id="formAdd"
            :validation-schema="user.schemas.add"
            @invalid-submit="(v) => console.log(v)"
            class="flex flex-col lg:flex-row pb-3 gap-3 justify-between"
        >
            <div class="dfcol gap-3">
                <Field :field="user.fields.add.email"/>
                <Field :field="user.fields.add.username"/>
                <Field :field="user.fields.add.first_name"/>
            </div>
            <div class="dfcol gap-8">
                <Field :field="user.fields.add.password" :value="password" @updateValue="(v, gen) => passGen(v, gen)"/>
                <Field :field="user.fields.add.passwordCheck" :value="passwordCheck"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAdd"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAdd = false"/>
        </template>        
    </Dialog>

    <!-- MODIFIER UN UTILISATEUR -->
    <Dialog v-model:visible="visibleEdit" modal :header="$t('icoaVue.editUser')" @after-hide="resetFields">
        <Form
            @submit="(v) => editUser(v)"
            id="formEdit"
            :validation-schema="user.schemas.edit"
            @invalid-submit="(v) => console.log(v)"
            class="dfrow pb-3 justify-between gap-3"
        >
            <div class="dfcol gap-3 w-full">
                <Field :field="user.fields.edit.email" :value="inEditObj.email"/>
                <Field :field="user.fields.edit.username" :value="inEditObj.username"/>
                <Field :field="user.fields.edit.first_name" :value="inEditObj.first_name"/>
            </div>
            <div class="dfcol gap-8">
                <Field :field="user.fields.edit.newPassword" :value="newPassword" @updateValue="(v, gen) => newPassGen(v, gen)"/>
                <Field :field="user.fields.edit.newPasswordCheck" :value="newPasswordCheck"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.edit')" severity="info" type="submit" form="formEdit"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleEdit = false"/>
        </template>        
    </Dialog>

    <!-- SUPPRIMER UN UTILISATEUR -->
    <Dialog v-model:visible="visibleDelete" modal :header="$t('icoaVue.delUser')">
        <div class="pb-3">
            <span>{{ $tic('icoaVue.confirmDelUser', {user: inEditObj.email}) }}</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteUser"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDelete = false"/>
        </template>        
    </Dialog>
</template>

<script setup>
import { nextTick, onBeforeMount, ref } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'
import Grants from './Grants.vue'

let user = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/icoadmin/user')
    user = new response.default()
    await awaitAllOFThem()
}


/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
 const RELOADTIME = ref(false)

/**
 * @const SHOWGRANTS permet de "load" le composant des grants
 */
 const SHOWGRANTS = ref(false)

const LISTE = ref([])

async function getListe(){
    LOADING.value = user.userText.load
    LISTE.value = await user.getListe()
    if(LISTE.value.constructor.name === 'Object'){
        LISTE.value = []
    }
}

async function getServices(){
    LISTE_SERVICES.value = []
    const response = await user.getListeService()
    response.forEach(service => {
        LISTE_SERVICES.value.push({
            id: service.id,
            ip: service.ip,
            name: service.name,
        })    
    })
}

async function awaitAllOFThem(){
    try {
        await getListe()
        await getServices()
        LOADING.value = null
    } catch (error) {
        if (LOADING.value) {
            user.getToast('error', '', user.mToast.getUsers.fail)
        }
    }
}
/* ------------------------------------- */

/* Champs et logique pour le formulaire */
const password = ref(null)
const passwordCheck = ref(null)
const newPassword = ref(null)
const newPasswordCheck = ref(null)

function passGen(pass, gen){
    password.value = pass
    if(gen){
        passwordCheck.value = pass
    }
}
function newPassGen(pass, gen){
    newPassword.value = pass
    if(gen){
        newPasswordCheck.value = pass
    }
}

function resetFields(){
    inEditObj.value = null
    password.value = null
    passwordCheck.value = null
    newPassword.value = ""
    newPasswordCheck.value = ""
}
/* ------------------------------------ */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAdd = ref(false)
const visibleEdit = ref(false)
const visibleDelete = ref(false)

const LISTE_SERVICES = ref([])

async function goToGrants(data) {
    SHOWGRANTS.value = false
    if (data) {
        inEditObj.value = await user.getUserById(data.id)
        delete inEditObj.value.password
        await nextTick()
        SHOWGRANTS.value = true
    }else{
        inEditObj.value = null
    }
}

async function goToEdit(data, modal){
    if(data){
        inEditObj.value = await user.getUserById(data.id)
        delete inEditObj.value.password
        modal ? visibleEdit.value = true : false
    }else{
        inEditObj.value = null
    }
}

function goToDelete(data){
    visibleDelete.value = true
    inEditObj.value = {...data}
}
/* ------------------------------------------------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

function addUser(values){
    console.log(values)
    values.grants = {}
    user.addUser(values)
    .then(async() => {
        user.getToast('info', '', user.mToast.addUser.success)
        await reloadTime()
        visibleAdd.value = false
    })
    .catch(async() => {
        user.getToast('error', '', user.mToast.addUser.fail)
        await reloadTime()
    })
}

function editUser(values){
    const data = checkAll(values)
    user.editUser(data)
    .then(async() => {
        user.getToast('info', '', user.mToast.editUser.success)
        await reloadTime()
        visibleEdit.value = false
    })
    .catch(async() => {
        user.getToast('error', '', user.mToast.editUser.fail)
        await reloadTime()
    })
}

function deleteUser(){
    user.deleteUser(inEditObj.value.id)
    .then(async() => {
        user.getToast('info', '', user.mToast.deleteUser.success)
        await reloadTime()
        visibleDelete.value = false
    })
    .catch(async() => {
        user.getToast('error', '', user.mToast.deleteUser.fail)
        await reloadTime()
    })
}

function checkAll(values){
    if(values.newPassword){
        values.password = values.newPassword
        values.passwordCheck = values.newPasswordCheck
    }
    delete values.newPassword
    delete values.newPasswordCheck
    const data = {...inEditObj.value}
    Object.keys(values).forEach(key => {
        if(values[key] !== data[key]){
            data[key] = values[key]
        }
    })
    return data
}

async function reloadTime(){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
}
/* -------------------------------------------------------------- */
</script>