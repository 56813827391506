var user = undefined
if(localStorage.getItem('user')){
  user = JSON.parse(localStorage.getItem('user'));
}
const initialState = user
  ? { status: { loggedIn: true }, user: user, serviceselected: null}
  : { status: { loggedIn: false }, user: null, serviceselected: null};

export const auth = {
  namespaced: true,
  state: initialState,
  getters:{
    getUser(state){
      return state.user
    },
    getServiceSelected(state){
      if (!state.serviceselected){
        return null
      }
      return state.serviceselected
    },
    getLoggedInfo(state){
      return state.status.loggedIn
    },
    getServices(state){
      if(!state.user?.info) {
        return null
      }
      return Object.keys(state.user.info.grants)
    }
  },
  actions: {
    login({ commit }, user) {
      commit('loginSuccess', user);
    },
    userInfo({ commit }, userinfo) {
      commit('userInfo', userinfo);
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },
    logout({ commit }) {
      commit('changeServiceSelected',null)
      commit('logout');
    },
    changeServiceSelected({ commit },service) {
      commit('changeServiceSelected',service);
    },
    loginFailure({ commit }) {
      commit('loginFailure')
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.info = null;
      state.user = null;
    },
    userInfo(state, info) {
      state.user.info = info;
    },
    refreshToken(state, token) {
      state.status.loggedIn = true;
      state.user.access = token;
    },
    changeServiceSelected(state, service) {
      state.serviceselected = service;
    }
  }
};