import Vuex from 'vuex';

import { index } from './index.store'
import { auth } from './auth.store'
import { mode } from './mode.store'
import { page } from './page.store'
import { lang } from './lang.store'

import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  modules: {
    index,
    mode,
    auth,
    statePage: page,
    lang
  },
  plugins: [vuexLocal.plugin]
});
