import { watch } from 'vue'
import api from '@/common/api'
import store from '@/store/store'


var serviceSelected = store.getters['auth/getServiceSelected']

watch(() => store.getters['auth/getServiceSelected'], () => {
  serviceSelected = store.getters['auth/getServiceSelected']
})


class ServiceShell {
    getPrompt(command) {
        return api.post(`/shell/${serviceSelected}`,{cmd: command})
    }
}

export default new ServiceShell()