import { nextTick, watch } from "vue"
import { toast, TRAD } from "@/main"
import axiosInstance from "@/common/api.js"
import axiosRefresh from "@/common/apiRefresh"
import TokenService from "@/services/token.service"
import router from '@/router/index'
import store from '@/store/store'


let serviceSelected = store.getters['auth/getServiceSelected']

watch(() => store.getters['auth/getServiceSelected'], () => {
  serviceSelected = store.getters['auth/getServiceSelected']
})


const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    async (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      // console.log(originalConfig)
      if(serviceSelected){
        if((err.response.status < 200 || err.response.status >= 300) && !originalConfig.url.includes('ping')){
          try {
            const res = await axiosInstance.get(`/ping/${serviceSelected}`)
            console.log('Le serveur répond bien !')
          } catch (error) {
            store.dispatch('auth/changeServiceSelected', null)
            router.go('/')
            toast.add({severity: 'error', summary: '', detail: TRAD('dt.serverError')})
          }
        }
      }
      if (originalConfig.url !== "/login" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosRefresh.post("/login/refresh/", {
              refresh: TokenService.getLocalRefreshToken(),
            });
            const { access } = rs.data;

            store.dispatch('auth/refreshToken', access);
            TokenService.updateLocalAccessToken(access);

            return axiosInstance(originalConfig);
          } catch (_error) {
            TokenService.removeUser();
            store.dispatch('auth/logout');
            router.go('login')
            return Promise.reject(_error);
        }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
