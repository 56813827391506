import { createRouter, createWebHistory } from 'vue-router'
import { computed, ref, watch } from 'vue'
import store from '@/store/store'
import ILogin from '@/pages/Login.vue'
import Index from '@/pages/Index.vue'
import NotFound from '@/pages/NotFound.vue'
import Apache from '@/pages/services/Apache.vue'
import Cron from '@/pages/services/Cron.vue'
import Database from '@/pages/services/Database.vue'
import Email from '@/pages/services/Email.vue'
import Ftp from '@/pages/services/Ftp.vue'
import RemoteAccess from '@/pages/services/RemoteAccess.vue'
import Icoadmin from '@/pages/admin/icoadmin/Icoadmin.vue'
import Terminal from '@/pages/admin/Terminal.vue'
import AuthService from "@/services/auth.service"
import { t } from '@/common/internationalization'

const user = computed(() => {
  return store.getters['auth/getUser']
})

const serviceSelected = ref(store.getters['auth/getServiceSelected'])
const userGrants = computed(() => {
  return user.value?.info?.grants[serviceSelected.value]
})

watch(() => store.getters['auth/getServiceSelected'], async() => {
  serviceSelected.value = store.getters['auth/getServiceSelected']
  await uInfos()
})

watch(userGrants, async() => {
  await uInfos()
})

async function uInfos(){
  await AuthService.userInfos()
  newRoutes()
}

const LABELS = t('router')

/** Définition des items dans la barre de navigation sur le côté */
export const routes = ref([
  {
    path: '/login',
    label: 'Login',
    component: ILogin,
    visible: false
  },
  {
    path: '/',
    icon: 'tableau-de-bord.svg',
    label: LABELS.dashboard.label,
    visible: true,
    component: Index
  },
  {
    path: '/web',
    icon: 'web.svg',
    label: LABELS.web.label,
    visible: userGrants.value ? userGrants.value.grants.web : false,
    component: Apache,
  },
  {
    path: '/database',
    icon: 'base-de-donnee.svg',
    label: LABELS.db.label,
    visible: userGrants.value ? userGrants.value.grants.database : false,
    component: Database,
  },
  {
    path: '/ftp',
    icon: 'ftp.svg',
    label: LABELS.ftp.label,
    visible: userGrants.value ? userGrants.value.grants.ftp : false,
    component: Ftp,
  },
  {
    path: '/cron',
    icon: 'tache-cron.svg',
    label: LABELS.cron.label,
    visible: userGrants.value ? userGrants.value.grants.cron : false,
    component: Cron,
  },
  {
    path: '/mail',
    icon: 'email.svg',
    label: LABELS.mail.label,
    visible: userGrants.value ? userGrants.value.grants.mail : false,
    component: Email,
  },
  {
    path: '/remoteaccess',
    icon: 'acces-distant.svg',
    label: LABELS.remote.label,
    visible: userGrants.value ? userGrants.value.grants.remoteaccess : false,
    component: RemoteAccess,
  },
  {
    path: '/admin',
    icon: 'superadmin.svg',
    label: LABELS.superadmin.label,
    visible: false,
    component: Icoadmin,
  },
  {
    path: '/shell',
    icon: 'trash.svg',
    label: LABELS.shell.label,
    visible: false,//userGrants.value ? userGrants.value.admin : false,
    component: Terminal,
  },
  //SI JE VEUX FAIRE DES TESTS AVEC L'API DU PROJET
  // {
  //   path: '/test',
  //   icon: 'trash.svg',
  //   label: 'Test',
  //   visible: false,
  //   component: NewTest,
  // },
  {
    path: '/monitoring',
    label: LABELS.monitor.label,
    icon: 'trash.svg',
    visible: false,
    component: Index
  },
])

const routesForRouter = []

routes.value.forEach(element => {
  if ("items" in element){
    element.items.forEach(it=> {
      if ('component' in it && 'path' in it){
        routesForRouter.push(
          {
            path: it.path,
            name: it.label,
            component: it.component
          }
        )
      }
    })
  }
  if ('component' in element && 'path' in element){
    routesForRouter.push(
      {
        path: element.path,
        name: element.label,
        component: element.component
      }
    )
  }
});

//NOT FOUND
routesForRouter.push({
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    props: true,
})

export const router = createRouter({
  history: createWebHistory(),
  routes: routesForRouter
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user');
  const isSuper = loggedIn ? JSON.parse(loggedIn)?.info?.is_superuser : false
  if(authRequired && !loggedIn){
    next('/login')
  }else if(!serviceSelected.value && !(isSuper && to.path.includes('admin')) && !to.name.includes('Dashboard') && !to.path.includes('login')){
    next('/login')
  } else {
    next()
  }
})


newRoutes()

function newRoutes() {
  if(userGrants.value){
    Object.keys(userGrants.value.grants).forEach(grant => {
      var route = routes.value.find(e => e.path === "/"+grant)
      if(route){
        //@todo TERMINAL A REACTIVER QUAND ON VEUT
        if(route.path === "/shell" || route.path === "/monitoring"){
          route.visible = false
        }
        else if(userGrants.value.admin){
          route.visible = true
        }
        else {
          route.visible = userGrants.value?.grants[grant]
        }
      }
    })
  }
}

export default router
