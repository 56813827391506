<template>
    <h1 class="mb-5">
        Gestion FTP
    </h1>
    <div v-if="LOADING === 'ERROR'">
        <span>{{ $t('dt.unavailable') }}</span>
    </div>
    <div v-else-if="(!LOADING && ftp) || RELOADTIME" class="mt-12">
        <CustomDatatable :liste="LISTE" :columns="ftp.columns" @dataSelected="goToEdit" :dataSelected="inEditObj" :fields="ftp.fields" export tName="users">
            <template #headerAction>
                <Button :label="$t('dt.add')" text @click="visibleAdd = true"/>
            </template>
            <template #actionColumn>
                <Column headerStyle="min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex flex-row-reverse">
                            <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDelete(slotProps.data)"/>
                            <Button icon="pi pi-file-edit" text severity="info" v-tooltip="$t('dt.edit')" @click="goToEdit(slotProps.data)"/>
                        </div>
                    </template>
                </Column>
            </template>
        </CustomDatatable>
    </div>
    <div v-else class="flex flex-col items-center md:h-screen lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>

    <!-- CRÉER UN UTILISATEUR FTP -->
    <Dialog v-model:visible="visibleAdd" modal :header="$t('ftpVue.addFtp')" @after-hide="resetFields">
        <Form
            @submit="(v) => addUser(v)"
            id="formAdd"
            :validation-schema="ftp.schemas.add"
            @invalid-submit="(v) => console.log(v)"
            class="flex flex-col lg:flex-row pb-3 gap-3 justify-between"
        >
            <div class="dfcol gap-3 w-full">
                <Field :field="ftp.fields.user"/>
                <Field :field="ftp.fields.directory" :value="directory"/>
            </div>
            <div class="dfcol gap-8">
                <Field :field="ftp.fields.password" :value="password" @updateValue="(v, gen) => passGen(v, gen)"/>
                <Field :field="ftp.fields.passwordCheck" :value="passwordCheck"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAdd"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAdd = false"/>
        </template>        
    </Dialog>
    <!-- MODIFIER UN UTILISATEUR FTP -->
    <Dialog v-model:visible="visibleEdit" modal :header="$t('ftpVue.editFtp')" @after-hide="resetFields">
        <Form
            @submit="(v) => updateUser(v)"
            id="formEdit"
            :validation-schema="ftp.schemas.edit"
            @invalid-submit="(v) => console.log(v)"
            class="dfrow pb-3 justify-between gap-3"
        >
            <div class="dfcol gap-3 w-full">
                <Field :field="ftp.fields.user" :value="inEditObj.user"/>
                <Field :field="ftp.fields.directory" :value="inEditObj.directory"/>
            </div>
            <div class="dfcol gap-8">
                <Field :field="ftp.fields.newPassword" :value="newPassword" @updateValue="(v, gen) => newPassGen(v, gen)"/>
                <Field :field="ftp.fields.newPasswordCheck" :value="newPasswordCheck"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.edit')" severity="info" type="submit" form="formEdit"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleEdit = false"/>
        </template>        
    </Dialog>
    <!-- SUPPRIMER UN UTILISATEUR FTP -->
    <Dialog v-model:visible="visibleDelete" modal :header="$t('ftpVue.deleteFtp')">
        <div class="pb-3">
            <span>{{ $t('ftpVue.confirmDeleteText') }} {{ inEditObj.user }}</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteUser"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDelete = false"/>
        </template>        
    </Dialog>
</template>

<script setup>
import { nextTick, onBeforeMount, ref } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'

let ftp = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/ftp')
    ftp = new response.default()
    await awaitAllOFThem()
}


/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
 const RELOADTIME = ref(false)

const LISTE = ref([])

async function getListe(){
    LOADING.value = ftp.ftpText.load.ftps
    LISTE.value = await ftp.getListe()
    // ftp.getToast('info', '', ftp.mToast.getFtp.success)
    if(LISTE.value.constructor.name === 'Object'){
        LISTE.value = []
    }
}

async function awaitAllOFThem(){
    try {
        await getListe()
        LOADING.value = null
    } catch (error) {
        if (LOADING.value) {
            ftp.getToast('error', '', ftp.mToast.getFtp.fail)
        }
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */

/* Champs et logique pour le formulaire */
const password = ref(null)
const passwordCheck = ref(null)
const newPassword = ref("")
const newPasswordCheck = ref("")
const directory = ref('/home/WwwBSD/')

function passGen(pass, gen){
    password.value = pass
    if(gen){
        passwordCheck.value = pass
    }
}
function newPassGen(pass, gen){
    newPassword.value = pass
    if(gen){
        newPasswordCheck.value = pass
    }
}

function resetFields(){
    inEditObj.value = null
    password.value = null
    passwordCheck.value = null
    newPassword.value = ""
    newPasswordCheck.value = ""
    directory.value = "/home/WwwBSD/"
}
/* ------------------------------------ */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAdd = ref(false)
const visibleEdit = ref(false)
const visibleDelete = ref(false)

function goToEdit(data){
    visibleEdit.value = true
    inEditObj.value = {...data}
}
function goToDelete(data){
    visibleDelete.value = true
    inEditObj.value = {...data}
}
/* ------------------------------------------------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

function addUser(values){
    const data = {...values}
    RELOADTIME.value = true
    ftp.addUser(data)
    .then(() => {
        ftp.getToast('info', '', ftp.mToast.addUser.success)
        visibleAdd.value = false
    })
    .catch(() => {
        ftp.getToast('error', '', ftp.mToast.addUser.fail)
    })
    .finally(async() => {
        await reloadTime()
    })
}
function updateUser(values){
    const data = {...values}
    if(values.newPassword){
        data.password = data.newPassword
        data.passwordCheck = data.newPasswordCheck
    }
    delete data.newPassword
    delete data.newPasswordCheck
    RELOADTIME.value = true
    ftp.updateUser(data)
    .then(() => {
        ftp.getToast('info', '', ftp.mToast.editUser.success)
        visibleEdit.value = false
    })
    .catch(() => {
        ftp.getToast('error', '', ftp.mToast.editUser.fail)
    })
    .finally(async() => {
        await reloadTime()
    })    
}
function deleteUser(){
    RELOADTIME.value = true
    ftp.deleteUser(inEditObj.value)
    .then(() => {
        ftp.getToast('info', '', ftp.mToast.delUser.success)
        visibleDelete.value = false
    })
    .catch(() => {
        ftp.getToast('error', '', ftp.mToast.delUser.fail)
    })
    .finally(async() => {
        await reloadTime()
    })   

}

async function reloadTime(){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
}
/* -------------------------------------------------------------- */
</script>