import {toast} from '@/main'

const ERROR = {
    summary: 'ERREUR API',
    detail: 'Un problème est survenu, veuillez réessayer ultérieurement ou contacter le support.'
}

const retour = (status, infos) => {
    toast.add({ 
        severity: severityRetour(status), 
        summary: infos.summary ? infos.summary : '',
        detail: infos.detail ? infos.detail : '',
        life: 4000 
    });
}

const errorRetour = (status) => {
    toast.add({ 
        severity: severityRetour(status), 
        summary: ERROR.summary,
        detail: ERROR.detail,
        life: 4000 
    });    
}

const dynaToast = {
    functions: {
        retour: retour,
        error: errorRetour
    }
}

function severityRetour(status) {
    switch (status) {
        case 401:
        case 403:
        case 404:
        case 500:
            return 'error'  
        case 200:
        case 201:
        case 204:
            return 'info'
    }
}

export default {
    install: (app) => {
        app.config.globalProperties.$dynaToast = dynaToast;
    }
}