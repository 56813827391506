import axios from "axios";
import TokenService from "../services/token.service";
import config from '@/config'

let headers = {
  "Content-Type": "application/json",
}
if (TokenService.getLocalAccessToken() !== undefined) {
  headers["Authorization"]  = 'Bearer ' + TokenService.getLocalAccessToken()
}

//@TODO : test si acces token est expired, si il est expire alors utilisation du refresh


const instance = axios.create({
  baseURL: config['apiUrl'],
  headers: headers
})

export default instance;
