const initialState = {
    apache: false,
}

export const mode = {
    namespaced: true,
    state: initialState,
    getters: {
        getApache(state) {
            return state.apache
        }
    },
    actions: {
        changeApache({ commit }) {
            commit('changeApache')
        }
    },
    mutations: {
        changeApache(state) {
            state.apache = !state.apache
        }
    }
}

export default mode