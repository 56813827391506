<template>
    <h1 class="mb-5">
        Gestion des bases de données et des utilisateurs
    </h1>
    <div v-if="LOADING === 'ERROR'">{{ $t('dt.unavailable') }}</div>
    <div v-else-if="(!LOADING && database) || RELOADTIME" class="dfcol gap-5 mt-12">
        <div>
            <div class="flex flex-col gap-2">
                <h1>Bases de données</h1>
                <div>
                    <a :href="`http://${selectedService}/PhpMyAdmin`" target="_blank"><Button icon="pi pi-external-link" :label="$t(`dt.phpAccess`)" iconPos="right" text severity="info" v-tooltip="$t('dt.bddTo')"/></a>
                </div>
            </div>
            <CustomDatatable :liste="LISTE" :columns="database.columns.db" :fields="DB_FIELDS" tName="databases">
                <template #headerAction>
                    <Button :label="$t('dt.add')" text @click="visibleAddDb = true"/>
                </template>
                <template #actionColumn>
                    <Column headerStyle="min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex flex-row-reverse">
                                <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDeleteDb(slotProps.data)"/>
                            </div>
                        </template>
                    </Column>
                </template>
            </CustomDatatable>
        </div>
        <div>
            <h1>Utilisateurs</h1>
            <CustomDatatable :liste="LISTE_USER" :columns="database.columns.user" @dataSelected="goToEdit" :dataSelected="inEditObj" :fields="USER_FIELDS" tName="users">
                <template #headerAction>
                    <Button :label="$t('dt.add')" text @click="visibleAddUser = true"/>
                </template>
                <template #actionColumn>
                    <Column headerStyle="min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex flex-row-reverse">
                                <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDeleteUser(slotProps.data)"/>
                                <Button icon="pi pi-file-edit" text severity="info" v-tooltip="$t('dt.edit')" @click="goToEdit(slotProps.data)"/>
                            </div>
                        </template>
                    </Column>
                </template>
            </CustomDatatable>
        </div>
    </div>
    <div v-else class="flex flex-col items-center md:h-screen lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>

    <!-- AJOUTER UNE BASE DE DONNÉES -->
    <Dialog v-model:visible="visibleAddDb" modal :header="$t('dbVue.addDb')" @after-hide="resetFields">
        <Form
            @submit="(v) => addDb(v)"
            id="formAddDb"
            :validation-schema="database.schemas.addDb"
            @invalid-submit="(v) => console.log(v)"
            class="pb-3"
        >
            <div class="flex justify-center">
                <Field :field="database.fields.name"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAddDb"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAddDb = false"/>
        </template>
    </Dialog>

    <!-- AJOUTER UN UTILISATEUR -->
    <Dialog v-model:visible="visibleAddUser" modal :header="$t('dbVue.addUser')" @after-hide="resetFields">
        <Form
            @submit="(v) => addUser(v)"
            id="formAddUser"
            :validation-schema="database.schemas.addUser"
            @invalid-submit="(v) => console.log(v)"
            class="pb-3"
        >
            <div class="flex flex-col sm:flex-row justify-between w-full">
                <Field :field="database.fields.user"/>
                <div class="dfcol gap-1">
                    <Field :field="database.fields.password" :value="password" @update-value="(v, gen) => passwordGen(v, gen)"/>
                    <Field :field="database.fields.passwordCheck" :value="passwordCheck"/>
                </div>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAddUser"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAddUser = false"/>
        </template>
    </Dialog>

    <!-- MODIFIER UN UTILISATEUR -->
    <Dialog v-model:visible="visibleEditUser" modal :header="`${$t('dbVue.editUser')} : ${inEditObj?.user}`" @hide="resetFields">
        <div class="dfcol gap-3 pb-3">
            <Form
                @submit="(v) => editUser(v)"
                id="formEditUser"
                :validation-schema="database.schemas.editUser"
                @invalid-submit="(v) => console.log(v)"
                class="dfcol gap-3 overflow-x-hidden"
            >
                <div class="flex justify-center w-full">
                    <div class="dfcol gap-1">
                        <Field :field="database.fields.newPassword" :value="newPassword" @update-value="(v, gen) => newPasswordGen(v, gen)"/>
                        <Field :field="database.fields.newPasswordCheck" :value="newPasswordCheck"/>
                    </div>
                </div>
                <div class="dfrow w-full justify-center">
                    <Button :label="$t('dt.edit')" severity="info" type="submit" form="formEditUser" v-tooltip="$t('dbVue.tooltipEditUser')"/>
                </div>
            </Form>
            <Fieldset :legend="$t('dbVue.fsUser')">
                <div class="dfrow justify-center mb-5">
                    <AutoComplete v-model="autocompleteDbName" :suggestions="userDbsAutoC" @complete="search" optionLabel="name" :placeholder="$t('dbVue.autoCompUserDbs')"/>
                </div>
                <div class="grid grid-cols-4 max-h-[250px] overflow-auto gap-3">
                    <div v-for="db in computedUserDbs" class="dfrow gap-1 justify-center">
                        <label class="truncate text-ellipsis overflow-hidden" v-tooltip="db">{{ db }}</label>
                        <Checkbox v-model="userDbs[db]" :input-id="db" @change="editUserGrants(db, userDbs[db])" :binary="true"/>
                    </div>
                </div>
            </Fieldset>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.modal.close')" text severity="info" @click="visibleEditUser = false"/>
        </template>
    </Dialog>

    <!-- SUPPRIMER UNE BASE DE DONNÉES -->
    <Dialog v-model:visible="visibleDeleteDb" modal :header="$t('dbVue.deleteDb')">
        <div class="pb-3">
            <span>{{ $t('dbVue.confirmDeleteDb') }} <b>{{ inEditObj.name }}</b> </span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteDb()"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDeleteDb = false"/>
        </template>
    </Dialog>

    <!-- SUPPRIMER UN UTILISATEUR -->
    <Dialog v-model:visible="visibleDeleteUser" modal :header="$t('dbVue.deleteUser')">
        <div class="pb-3">
            <span>{{ $t('dbVue.confirmDeleteUser') }} {{ inEditObj.user }}</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteUser"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDeleteUser = false"/>
        </template>
    </Dialog>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, ref } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'
import { useStore } from 'vuex'

const store = useStore()
const selectedService = ref()

let database = null

onBeforeMount(async() => {
    await importedFiles()
    selectedService.value = store.getters['auth/getServiceSelected']
})

async function importedFiles(){
    const response = await import('@/models/database')
    database = new response.default()
    await awaitAllOFThem()
}

/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)
/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
 const RELOADTIME = ref(false)

const LISTE = ref([])
const LISTE_USER = ref([])
const DB_FIELDS = {}
const USER_FIELDS = {}

async function getListe(){
    const array = []
    LOADING.value = database.dbText.load.bdd
    DB_FIELDS.name = database.fields.name
    DB_FIELDS.users = database.fields.users
    const res = await database.listeDb()
    res.forEach((db, i) => {
        array.push({name: db, users: []})
    })
    LISTE.value = array
    // RELOADTIME.value ? null : database.getToast('info', '', database.mToast.getDB.success)
}

async function getUsers(){
    const array = []
    LOADING.value = database.dbText.load.users
    USER_FIELDS.user = database.fields.user
    USER_FIELDS.dbaccess = database.fields.dbaccess
    const res = await database.listUsers()
    Object.keys(res).forEach(user => {
        array.push({user: user, dbaccess: res[user]})
    })
    LISTE_USER.value = array
    usersAccesses()
    // RELOADTIME.value ? null : database.getToast('info', '', database.mToast.getUsers.success)
}

function usersAccesses(){
    const not_db = []
    LISTE_USER.value.forEach(user => {
        if(user.dbaccess.length > 0){
            user.dbaccess.forEach((userDb, index) => {
                const i = LISTE.value.findIndex(db => db.name === userDb)
                if(i !== -1){
                    LISTE.value[i].users.push(user.user)
                }else {
                    not_db.push(userDb)
                }
            })
        }
    })
    if(not_db.length > 0){
        not_db.forEach(db => {
            LISTE_USER.value.forEach(user => {
                const i = user.dbaccess.findIndex(dbN => dbN === db)
                if(i !== -1){
                    user.dbaccess.splice(i, 1)
                }
            })
        })
    }
}

async function awaitAllOFThem(){
    try {
        if(LOADING.value === database.dbText.load.users){
            await getUsers()
        }else {
            await getListe()
            await getUsers()
        }
        LOADING.value = null
    } catch (error) {
        if (LOADING.value === database.dbText.load.bdd) {
            database.getToast('error', '', database.mToast.getDB.fail)
        }else {
            database.getToast('error', '', database.mToast.getUsers.fail)
        }
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */

/* Champs et logique pour les formulaires */
const password = ref(null)
const passwordCheck = ref(null)

const newPassword = ref(null)
const newPasswordCheck = ref(null)

function passwordGen(pass, gen){
    password.value = pass
    if(gen){
        passwordCheck.value = pass
    }
}
function newPasswordGen(pass, gen){
    newPassword.value = pass
    if(gen){
        newPasswordCheck.value = pass
    }
}

function resetFields(){
    inEditObj.value = null
    password.value = null
    passwordCheck.value = null
    newPassword.value = null
    newPasswordCheck.value = null
}
/* ------------------------------------------------------------ */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAddDb = ref(false)
const visibleDeleteDb = ref(false)
const visibleAddUser = ref(false)
const visibleEditUser = ref(false)
const visibleDeleteUser = ref(false)

const userDbs = ref(null)

function goToEdit(data){
    visibleEditUser.value = true
    inEditObj.value = {...data}
    userDbs.value = userDbAccess()
}
function goToDeleteDb(data){
    visibleDeleteDb.value = true
    inEditObj.value = {...data}
}
function goToDeleteUser(data){
    visibleDeleteUser.value = true
    inEditObj.value = {...data}
}

function userDbAccess(){
    const userDbs = {}
    LISTE.value.forEach(db => {
        if(db.users.includes(inEditObj.value.user)){
            userDbs[db.name] = true
        }else{
            userDbs[db.name] = false
        }
    })
    return userDbs
}


//Changement des autorisations base de données pour un utilisateur
const autocompleteDbName = ref("")
const userDbsAutoC = ref([])
const search = (event) => {
    userDbsAutoC.value = LISTE.value.filter(db => db.name.includes(event.query))
}

const computedUserDbs = computed(() => {
    if(userDbs.value){
        return Object.keys(userDbs.value).filter(db => db.includes(autocompleteDbName.value))
    }
    return null
})

/* ------------------------------------------------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

function addDb(values){
    RELOADTIME.value = true
    database.addDb(values)
    .then(async() => {
        database.getToast('info', '', database.mToast.addDB.success)
        await reloadTime()
        visibleAddDb.value = false
    })
    .catch(async() => {
        database.getToast('error', '', database.mToast.addDB.fail)
        await reloadTime()
    })
}
function addUser(values){
    RELOADTIME.value = true
    database.addUser(values)
    .then(async() => {
        database.getToast('info', '', database.mToast.addUser.success)
        await reloadTime()
        visibleAddUser.value = false
    })
    .catch(async() => {
        database.getToast('error', '', database.mToast.addUser.fail)
        await reloadTime()
    })
}
function editUser(values){
    const data = {
        user: inEditObj.value.user,
        password: values.newPassword,
        passwordCheck: values.newPasswordCheck,
    }
    RELOADTIME.value = true
    database.editUser(data)
    .then(async() => {
        database.getToast('info', '', database.mToast.editUP.success)
        await reloadTime()
        visibleEditUser.value = false
    })
    .catch(async() => {
        database.getToast('error', '', database.mToast.editUP.fail)
        await reloadTime()
    })
}
function editUserGrants(dbName, value){
    const user = inEditObj.value.user
    const keyName = value ? 'addUG' : 'delUG'
    RELOADTIME.value = true
    database.editUserGrants(user, dbName, value)
    .then(async() => {
        database.getToast('info', '', database.mToast[keyName].success)
        await reloadTime()
    })
    .catch(async() => {
        database.getToast('info', '', database.mToast[keyName].success)
        await reloadTime()
    })
}
function deleteDb(){
    console.log(inEditObj.value)
    RELOADTIME.value = true
    database.deleteDb(inEditObj.value)
    .then(async() => {
        database.getToast('info', '', database.mToast.addDB.success)
        await reloadTime()
        visibleDeleteDb.value = false
    })
    .catch(async() => {
        database.getToast('error', '', database.mToast.delDB.fail)
        await reloadTime()
    })
}
function deleteUser(){
    RELOADTIME.value = true
    database.deleteUser(inEditObj.value)
    .then(async() => {
        database.getToast('info', '', database.mToast.delUser.success)
        await reloadTime()
        visibleDeleteUser.value = false
    })
    .catch(async() => {
        database.getToast('error', '', database.mToast.delUser.fail)
        await reloadTime()
    })
}

async function reloadTime(){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
}

/* -------------------------------------------------------------- */
</script>