<template>
    <div>
        <div class="dfrow gap-2 mb-3 items-center">
            <span>{{ $t('icoaVue.daysLogs') }}</span>
            <Calendar v-model="date" dateFormat="yy-mm-dd"></Calendar>
            <Button icon="pi pi-refresh" size="small" text @click="awaitAllOFThem()" v-tooltip="$t('dt.reload')"/>
        </div>
        <div v-if="LOADING === 'ERROR'">
            <span>{{ $t('dt.unavailable') }}</span>
        </div>
        <CustomDatatable v-else-if="(!LOADING && logs)" :liste="LISTE" :columns="logs.columns" :fields="{}" tName="icoLogs">
        </CustomDatatable>
        <div v-else class="flex flex-col items-center md:h-screen h-fit lg:py-0 gap-5">
            <Loading :message="LOADING"/>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue'
import { TIC } from '@/main'

const date = ref(new Date())

let logs = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/icoadmin/logs')
    logs = new response.default()
    await awaitAllOFThem()
}


/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)


const LISTE = ref([])

async function getListe(){
    const formDate = dateFormatter(date.value)
    LOADING.value = TIC('icoadmin.logs.load', {date: formDate})
    const response = await logs.getLogs(formDate)
    if(response.data){
        LISTE.value = response.data.reverse()
    }
}

async function awaitAllOFThem(){
    try {
        await getListe()
        LOADING.value = null
    } catch (error) {
        logs.getToast('error', '', logs.mToast.getLogs.fail)
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */


watch(date, async()=>{
    await awaitAllOFThem()
})

const dateFormatter = (date) => {
    const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`
    const month = date.getMonth() + 1 >= 10 ? date.getMonth()+1 : `0${date.getMonth()+1}`
    return `${date.getFullYear()}-${month}-${day}`
}
</script>