<template>
    <h1 class="mb-5">
        {{ $t('router.web.title') }}
    </h1>
    <div v-if="LOADING === 'ERROR'">{{ $t('dt.unavailable') }}</div>
    <div v-else-if="(!LOADING && web) || RELOADTIME" class="mt-12">
        <CustomDatatable :liste="LISTE" :columns="web.columns" @dataSelected="goToEdit" :dataSelected="inEditObj" :fields="web.fields" export tName="apache">
            <template #headerAction>
                <Button v-model="mode" :label="$t('webVue.expert')" :icon="mode ? 'pi pi-check-circle' : 'pi pi-circle'" :severity="mode ? 'warning' : null" text @click="checkMode()" />
                <Button :label="$t('dt.add')" text @click="visibleAdd = true"/>
            </template>
            <template #actionColumn>
                <Column headerStyle="min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex flex-row-reverse">
                            <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDelete(slotProps.data)"/>
                            <Button icon="pi pi-file-edit" text v-tooltip="$t('dt.edit')" @click="goToEdit(slotProps.data)"/>
                        </div>
                    </template>
                </Column>
            </template>
        </CustomDatatable>
    </div>
    <div v-else class="flex flex-col items-center md:h-screen lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>
    <!-- AJOUTER UN VHOST -->
    <Dialog v-model:visible="visibleAdd" modal :header="$t('webVue.addVhost')" @hide="resetFields">
        <Form
            @submit="(v) => addVhost(v)"
            id="formAdd"
            :validation-schema="web.schemas.add"
            @invalid-submit="(v) => console.log(v)"
            class="pb-3"
        >
            <div class="dfcol gap-3">
                <div class="grid grid-cols-2 gap-3">
                    <Field :field="web.fields.servername" @updateValue="(v) => repertoryChange(v)"/>
                    <Field :field="web.fields.documentroot" :value="documentroot"/>
                    <div v-if="!mode" class="dfrow gap-1">
                        <Field :field="web.fields.port"/>
                        <!-- <span class="pi pi-question border rounded-full mt-0.5 p-0.5 h-fit hover:cursor-pointer" style="font-size: 10px" v-tooltip="$t('webVue.helperPort')"></span> -->
                    </div>
                    <Field v-else :field="web.fields.portExpert"/>
                    <Field :field="web.fields.phpversion"/>
                </div>

                <div class="dfcol gap-3">
                    <Field :field="web.fields.addDb" :value="addDb" @updateValue="(v) => addDb = v" class="col-span-2" />
                    <div v-if="addDb" class="grid grid-cols-2 gap-2">
                        <div class="dfcol gap-3">
                            <Field :field="web.fields.bddname"/>
                            <Field :field="web.fields.user" />
                        </div>
                        <div class="dfcol gap-8">
                            <Field :field="web.fields.password" :value="dbPassword" @updateValue="(v, gen) => dbPassGen(v, gen)"/>
                            <Field :field="web.fields.passwordCheck" :value="dbPasswordCheck" />
                        </div>
                    </div>
                </div>

                <div class="dfcol gap-3">
                    <Field :field="web.fields.addFtp" :value="addFtp" @updateValue="(v) => addFtp = v" class="col-span-2" />
                    <div v-if="addFtp" class="grid grid-cols-2 gap-2">
                        <div class="dfcol gap-3">
                            <Field :field="web.fields.ftpUser"/>
                            <Field v-show="false" :field="web.fields.ftpDirectory" :value="ftpDirectory"/>
                        </div>
                        <div class="dfcol gap-8">
                            <Field :field="web.fields.ftpPassword" :value="ftpPassword" @updateValue="(v, gen) => ftpPassGen(v, gen)"/>
                            <Field :field="web.fields.ftpPasswordCheck" :value="ftpPasswordCheck" />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <div class="dfrow justify-between w-full">
                <div>
                    <Button v-model="mode" :label="$t('webVue.expert')" :icon="mode ? 'pi pi-check-circle' : 'pi pi-circle'" :severity="mode ? 'warning' : 'info'" text @click="checkMode()" />
                </div>
                <div>
                    <Button :label="$t('dt.add')" severity="info" type="submit" form="formAdd"/>
                    <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAdd = false"/>
                </div>
            </div>
        </template>
    </Dialog>

    <!-- MODIFIER UN VHOST -->
    <Dialog v-model:visible="visibleEdit" modal :header="$t('webVue.editVhost')" @hide="resetFields">
        <div class="static dfcol gap-3 mb-3 rounded">
            <div class="dfrow gap-1">
                <Field :field="web.fields.phpversion" :value="phpversion" @updateValue="(v) => phpversion = v" :disabled="RELOADTIME"/>
                <div class="flex flex-col-reverse">
                    <Button :label="$t('dt.edit')" text @click="migratePhpVersion" :disabled="checkPhpChange"/>
                </div>
            </div>
            <div v-if="mode" class="dfcol gap-3">
                <div class="dfcol gap-1">
                    <Field :field="web.fields.directivesplaintext" :value="directivesplaintext" @updateValue="(v) => directivesplaintext = v"  :disabled="RELOADTIME"/>
                    <div class="flex flex-row-reverse pr-1">
                        <Button :label="$t('dt.edit')" @click="updateVhost" :disabled="checkDirectivesChange"/>
                    </div>
                </div>
                <div v-if="inEditObj.phpversion !== 'none'" class="dfcol gap-1">
                    <Field :field="web.fields.contentplaintext" :value="contentplaintext" @updateValue="(v) => contentplaintext = v"  :disabled="RELOADTIME"/>
                    <div class="flex flex-row-reverse pr-1">
                        <Button :label="$t('dt.edit')" @click="updateConfPhp" :disabled="checkContentChange"/>
                    </div>
                </div>
            </div>
            <div class="dfcol gap-2">
                <div class="dfrow gap-1 w-fit">
                    <Field :field="web.fields.alias" :value="alias" @updateValue="(v) => alias = v" @keyup.enter="addAlias" :disabled="RELOADTIME"/>
                    <div class="flex items-end">
                        <Button icon="pi pi-plus" text severity="info" @click="addAlias" v-tooltip="$t('webVue.addAlias')" :loading="RELOADTIME"/>
                    </div>
                </div>
                <div v-if="!RELOADTIME" class="dfrow flex-wrap gap-1">
                    <Chip v-for="alias in inEditObj.alias" :label="alias" removable @remove="deleteAlias(alias)"/>
                </div>
            </div>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <div class="dfrow justify-between w-full">
                <div>
                    <Button v-model="mode" :label="$t('webVue.expert')" :icon="mode ? 'pi pi-check-circle' : 'pi pi-circle'" :severity="mode ? 'warning' : 'info'" text @click="checkMode()" />
                </div>
                <div class="dfrow gap-3">
                    <Button :label="$t('dt.modal.close')" text severity="info" @click="visibleEdit = false"/>
                </div>
            </div>
        </template>
    </Dialog>

    <!-- SUPPRIMER UN VHOST -->
    <Dialog v-model:visible="visibleDelete" modal :header="$t('webVue.deleteVhost')">
        <div class="pb-3">
            <span>{{ $t('webVue.confirmDeleteText') }} {{ inEditObj.servername }}</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="confirmedDelete()"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDelete = false"/>
        </template>
    </Dialog>

    <!-- Changer de mode (from zero to hero) -->
    <Dialog v-model:visible="expertVisible" modal :header="$t('webVue.warning')">
        <div class="mb-5">
            <p>{{ $t('webVue.expertModal.line_one') }}</p>
            <p>{{ $t('webVue.expertModal.line_two') }}</p>
        </div>
        <template #footer>
            <Button :label="$t('dt.cancel')" severity="danger" @click="expertVisible = false" />
            <Button :label="$t('webVue.expertModal.confirm_switch')" severity="info" @click="changeMode()"/>               
        </template>
    </Dialog>
</template>

<script setup>
import { ref, computed, onBeforeMount, nextTick, onUnmounted } from 'vue'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import Field from '@/components/NewField.vue'

const store = useStore()

let web = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/web')
    web = new response.default()
    await awaitAllOFThem()
}

/* Récupération des informations en base avec un chargement*/

/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
const LOADING = ref(null)

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
const RELOADTIME = ref(false)


const LISTE = ref([])
const LISTE_CONFS = ref([])

async function getListe(){
    LOADING.value = web.webText.load.vhosts
    const response = await web.getListe()
    if(response.length){
        LISTE.value = response
        LISTE.value.forEach(vhost => {
            if(vhost.phpversion === 'NULL'){
                vhost.phpversion = 'none'
            }
        })
    }
    // RELOADTIME.value ? null : web.getToast('info', '', web.mToast.getVhosts.success)
}

async function getVersionsPhp(){
    LOADING.value = web.webText.load.versionsPHP
    await web.getVersionsPhp()
}

async function getAllConfPhp(){
    LOADING.value = web.webText.load.confPHP
    LISTE_CONFS.value = await web.getAllConfPhp()
    linkPhpConfToVhost()
}

function linkPhpConfToVhost(){
    LISTE.value.forEach(vhost => {
        const phpConf = LISTE_CONFS.value.find(conf => (conf.domain === vhost.servername && conf.version === vhost.phpversion))
        if(phpConf){
            vhost.contentplaintext = phpConf.contentplaintext
        }else{
            vhost.contentplaintext = null
        }
    })
}

async function awaitAllOFThem(){
    try {
        if(LOADING.value === web.webText.load.confPHP){
            await getAllConfPhp()
        }else if(LOADING.value === web.webText.load.versionsPHP){
            await getVersionsPhp()
            await getAllConfPhp()
        }else {
            await getListe()
            await getVersionsPhp()
            await getAllConfPhp()
        }
        LOADING.value = null
    } catch (error) {
        switch (LOADING.value) {
            case web.webText.load.vhosts:
                web.getToast('error', '', web.mToast.getVhosts.fail)
                break;
            case web.webText.load.versionsPHP:
                web.getToast('error', '', web.mToast.getVersionsPHP.fail)
                break;
            default:
                web.getToast('error', '', web.mToast.getConfsPHP.fail)
                break;
        }
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */

/* Champs et logique pour le formulaire de CRÉATION D'UN VHOST */
const addDb = ref(false)
const dbPassword = ref(null)
const dbPasswordCheck = ref(null)

const addFtp = ref(false)
const ftpPassword = ref(null)
const ftpPasswordCheck = ref(null)

const documentroot = ref(null)
const ftpDirectory = ref(null)

const phpversion = ref(null)
const directivesplaintext = ref(null)
const contentplaintext = ref(null)
const alias = ref(null)

function dbPassGen(pass, gen){
    dbPassword.value = pass
    if(gen){
        dbPasswordCheck.value = pass
    }
}
function ftpPassGen(pass, gen){
    ftpPassword.value = pass
    if(gen){
        ftpPasswordCheck.value = pass
    }
}
function repertoryChange(value){
    documentroot.value = '/home/WwwBSD/'+value
    ftpDirectory.value = '/home/WwwBSD/'+value
}
function resetFields(){
    inEditObj.value = null
    addDb.value = false
    dbPassword.value = null
    dbPasswordCheck.value = null
    addFtp.value = false
    ftpPassword.value = null
    ftpPasswordCheck.value = null
    documentroot.value = null
    ftpDirectory.value = null
    phpversion.value = null
    directivesplaintext.value = null
    contentplaintext.value = null
    alias.value = null
}
/* ------------------------------------------------------------ */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAdd = ref(false)
const visibleEdit = ref(false)
const visibleDelete = ref(false)
const expertVisible = ref(false)

function goToEdit(data){
    visibleEdit.value = true
    inEditObj.value = {...data}
    const alias = data.alias
    if(data.phpversion !== 'none'){
        phpversion.value = {value: data.phpversion, label: data.phpversion}
    }else {
        phpversion.value = {value: data.phpversion, label: 'Aucune'}
    }
    directivesplaintext.value = data.directivesplaintext
    contentplaintext.value = data.contentplaintext
    inEditObj.value.alias = []
    inEditObj.value.alias = alias
}
function goToDelete(data){
    visibleDelete.value = true
    inEditObj.value = {...data}
}
/* ------------------------------------------------------------------------------ */

/* Changement de mode de gestion APACHE */
const mode = computed(() => {
    return store.getters['mode/getApache']
})

function checkMode() {
    if(mode.value){
        mode.value = false
        store.dispatch('mode/changeApache', !mode.value)
    }else {
        expertVisible.value = true
    }
}
function changeMode() {
    store.dispatch('mode/changeApache', !mode.value)
    expertVisible.value = false
}
/* ------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

async function addVhost(values){
    let port = null
    if(mode.value){
        port = values.port
    }else{
        port = values.port.value
    }
    const vhost = { domain: values.servername, phpVersion: values.phpversion.value, port: port, directory: values.documentroot }
    const db = addDb.value ? { name: values.bddname } : null
    const dbUser = addDb.value ? { user: values.user, password: values.password, passwordCheck: values.passwordCheck } : null
    const ftpUser = addFtp.value ? { password: values.ftpPassword, passwordCheck: values.ftpPasswordCheck, user: values.ftpUser, directory: values.ftpDirectory } : null
    RELOADTIME.value = true
    web.addVhost(vhost)
    .then(async() => {
        web.getToast('info', '', web.mToast.addVhost.success, 10000)
        if(addDb.value){
            wAddDb(db, dbUser)
        }
        if(addFtp.value){
            wAddFtp(ftpUser)
        }
        visibleAdd.value = false
        await reloadTime()
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.addVhost.fail)
        await reloadTime()
    })
}

function wAddDb(db, dbUser){
    web.addDb(db)
    .then(() => {
        // web.getToast('info', '', web.mToast.addDb.success)
        web.addDbUser(dbUser)
        .then(() => {
            // web.getToast('info', '', web.mToast.addDbUser.success)
            web.addUserGrants(db.name, dbUser.user)
            .then(() => {
                // web.getToast('info', '', web.mToast.addUserGrants.success)
                web.getToast('info', '', web.mToast.fullAddUser.success)
            })
            .catch(() => {
                web.getToast('error', '', web.mToast.addUserGrants.fail)                
            })
        })
        .catch(() => {
            web.getToast('error', '', web.mToast.addDbUser.fail)            
        })
    })
    .catch(() => {
        web.getToast('error', '', web.mToast.addDb.fail)
    })
}

function wAddFtp(ftpUser){
    web.addFtp(ftpUser)
    .then(() => {
        web.getToast('info', '', web.mToast.addFtp.success)        
    })
    .catch(() => {
        web.getToast('error', '', web.mToast.addFtp.fail)
    })
}

async function updateVhost(){
    const data = {directives: directivesplaintext.value, domain: inEditObj.value.servername, port: inEditObj.value.port}
    if(directivesplaintext.value === inEditObj.value.directivesplaintext){
        web.getToast('warn', '', web.mToast.upVhost.same)
        return false
    }
    RELOADTIME.value = true
    web.updateVhost(data)
    .then(async(response) => {
        web.getToast('info', '', web.mToast.upVhost.success)
        await reloadTime(true)
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.upVhost.fail)
        await reloadTime(true)
    })
}

async function updateConfPhp(){
    const data = {directives: contentplaintext.value, domain: inEditObj.value.servername, port: inEditObj.value.port, phpVersion: phpversion.value.value}
    RELOADTIME.value = true
    web.updateConfPhp(data)
    .then(async(response) => {
        web.getToast('info', '', web.mToast.upConfPhp.success)
        await reloadTime(true)
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.upConfPhp.fail)
        await reloadTime(true)
    })
}

async function migratePhpVersion(){
    const data = {
        phpVersion: inEditObj.value.phpversion,
        newPhpVersion: phpversion.value.value,
        directives: directivesplaintext.value, 
        domain: inEditObj.value.servername, 
        port: inEditObj.value.port,
    }
    RELOADTIME.value = true
    web.migratePhpVersion(data)
    .then(async(response) => {
        inEditObj.value.phpversion = data.newPhpVersion
        web.getToast('info', '', web.mToast.migPhp.success)
        await reloadTime(true)
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.migPhp.fail)
        await reloadTime(true)
    })

}

async function addAlias(){
    const data = {alias: alias.value, domain: inEditObj.value.servername, port: inEditObj.value.port}
    //CHECK REGEX ALIAS
    if(!alias.value){
        web.getToast('warn', '', web.mToast.addAlias.empty)
        return false
    }
    RELOADTIME.value = true
    web.addVhostAlias(data)
    .then(async(response) => {
        web.getToast('info', '', web.mToast.addAlias.success)
        await reloadTime(true)
        alias.value = null
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.addAlias.fail)
        await reloadTime(true)
    })
}

async function deleteAlias(alias){
    const data = {alias: alias, domain: inEditObj.value.servername, port: inEditObj.value.port}
    RELOADTIME.value = true
    web.deleteVhostAlias(data)
    .then(async(response) => {
        web.getToast('info', '', web.mToast.delAlias.success)
        await reloadTime(true)
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.delAlias.fail)
        await reloadTime(true)
    })
}

function confirmedDelete(){
    const delVhost = {domain: inEditObj.value.servername, port: inEditObj.value.port}
    RELOADTIME.value = true
    web.deleteVhost(delVhost)
    .then(async(response) => {
        web.getToast('info', '', web.mToast.deleteVhost.success)
        await reloadTime()
        visibleDelete.value = false
    })
    .catch(async(e) => {
        web.getToast('error', '', web.mToast.deleteVhost.fail)
        await reloadTime()
    })
}

async function reloadTime(edit){
    await awaitAllOFThem()
    if (edit) {
        const vhost = inEditObj.value
        goToEdit(LISTE.value.find(a => (
            a.servername === vhost.servername &&
            a.phpversion === vhost.phpversion &&
            a.port === vhost.port
        )))
    }
    await nextTick()
    RELOADTIME.value = false
}
/* -------------------------------------------------------------- */

/* Check des changements pour activer/désactiver bouton "modifier" */

const checkPhpChange = computed(() => {
    return phpversion.value.value === inEditObj.value.phpversion
})

const checkDirectivesChange = computed(() => {
    return directivesplaintext.value === inEditObj.value.directivesplaintext
})

const checkContentChange = computed(() => {
    return contentplaintext.value === inEditObj.value.contentplaintext
})

/* --------------------------------------------------------------- */
</script>