export const inputTextStickButton ={
    root: {
        class: [
            'w-full h-9',
            'rounded-l-lg border border-gray-300',
            'px-2 p-2',
            'text-xs font-sans',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            'dark:border-blue-900/90 transition-colors duration-200 appearance-none hover:border-blue-500',
            'dark:text-white/80 bg-white dark:bg-gray-900'
        ]
    }
}

export const inputTextBasePrime = {
    root: ({ props, context }) => ({
        class: [
            'm-0',
            'font-sans text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-lg',
            {
                'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                'opacity-60 select-none pointer-events-none cursor-default': context.disabled
            },
            {
                'text-lg px-4 py-4': props.size == 'large',
                'text-xs px-2 py-2': props.size == 'small',
                'p-2 w-full text-base': props.size == null
            }
        ]
    })

}

export const passwordInput = {
    input: {
        class: [
            'bg-red-700'
        ]
    }
}

export const inputTextBase = {
    root: {
        class: [
            'w-full',
            'rounded-lg border border-gray-300',
            'px-2 p-2',
            'text-xs font-sans',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            'dark:border-blue-900/90 transition-colors duration-200 appearance-none rounded-lg hover:border-blue-500',
            'dark:text-white/80 bg-white dark:bg-gray-900'
        ]
    }
}

export const inputNumberStickButton = {
    root: 'w-full inline-flex',
    input: ({ props }) => ({
        class: [
            'w-full h-9',
            'rounded-l-lg border border-gray-300',
            'px-2 p-2',
            'text-xs font-sans',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            'dark:border-blue-900/90 transition-colors duration-200 appearance-none hover:border-blue-500',
            'dark:text-white/80 bg-white dark:bg-gray-900'
        ]
    }),
    buttongroup: ({ props }) => ({
        class: [{ 'flex flex-col': props.showButtons && props.buttonLayout == 'stacked' }]
    }),
    label: 'hidden',
}

export const inputPasswordStickButton = {
    root: ({ props }) => ({
        class: [
            'inline-flex relative',
            'w-full h-9',
            'rounded-l-lg border border-gray-300',
            'px-2 p-2',
            'text-xs font-sans',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            'dark:border-blue-900/90 transition-colors duration-200 appearance-none hover:border-blue-500',
            'dark:text-white/80 bg-white dark:bg-gray-900',
            {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled
            }
        ]
    }),
    showicon: {
        class: ['absolute top-1/2 -mt-2', 'right-8 text-gray-600 dark:text-white/70']
    },
    hideicon: {
        class: ['absolute top-1/2 -mt-2', 'right-8 text-gray-600 dark:text-white/70']
    },
}

export const inputSwitch = {
    root: ({ props }) => ({
        class: [
            'inline-block relative',
            'w-12 h-7',
            {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled
            }
        ]
    }),
    slider: ({ props }) => ({
        class: [
            'absolute cursor-pointer top-0 left-0 right-0 bottom-0 border border-transparent',
            'transition-colors duration-200 rounded-2xl',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
            "before:absolute before:content-'' before:top-1/2 before:bg-white before:dark:bg-gray-900 before:w-5 before:h-5 before:left-1 before:-mt-2.5 before:rounded-full before:transition-duration-200",
            {
                'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 hover:dark:bg-gray-700 ': !props.modelValue,
                'bg-blue-500 before:transform before:translate-x-5': props.modelValue
            }
        ]
    })
}

export const inputTextLogin = {
    root: ({ context, instance }) => ({
        class: [
            "font-sans px-5 py-2 font-medium",

            // Colors
            'text-surface-900 dark:text-surface-0',
            'placeholder:text-surface-400 dark:placeholder:text-surface-500',
            'bg-surface-0 dark:bg-surface-900',
            'ring-1 ring-inset ring-surface-300 dark:ring-surface-700 ring-offset-0',
            'shadow-sm',
            // States
            {
                'border dark:border-red-500': instance.$attrs.class === 'p-invalid',
                "hover:border-white/40": !context.disabled,
                "focus:outline-none focus:bg-white/20": !context.disabled,
                "bg-white/10 select-none pointer-events-none cursor-default":
                    context.disabled,
            },

            // Misc
            "rounded-full",
            "transition-colors duration-300",
        ],
    }),
}