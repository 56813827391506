import store from '@/store/store'

const lang = store.getters['lang/getLang']

const FUSED = await getTranslations(lang.label, lang.fallback)

//Si on envoie des clés qui possèdent plusieurs clés elles mêmes, ça va renvoyer un objet contenant toutes les trads de cet objet.
//Si on envoie une clé précise, cela renvoie directement la chaine de caractère
export function t(sKeys){
    if(sKeys){
        let aKeys = []
        aKeys = sKeys.split('.')
        const value = aKeys.reduce((a, b) => a?.[b] ?? null, FUSED)
        return value
    }
    return FUSED
}

function tic(sKeys, values){
    let str = t(sKeys)
    if(Object.keys(values).includes('count')){
        str = doku(str, values.count)
    }
    Object.entries(values).forEach(t => {
        var regex = new RegExp(`{{\\s*(${t[0]})\\s*}}`, "g")
        str = str.replace(regex, t[1])
    })
    return str
}

function doku(str, count){
    switch (count) {
        case 0:
            return str.zero
        case 1:
            return str.one
        default:
            return str.mult
    }
}

//RETURN RESPONSE

export default {
    install: (app) => {
        app.config.globalProperties.$t = t;
        app.config.globalProperties.$tic = tic;
        app.provide('t', t)
        app.provide('tic', tic)
    }
}

async function getTranslations(lang, fallback){
    const USER_LANG = await toTheObject(lang)
    const FALLBACK_LANG = await toTheObject(fallback)
    const FUSED = getValues(FALLBACK_LANG, USER_LANG)
    return FUSED
}

async function toTheObject(lang){
    return await import(`@yaml/${lang}`)
}

function getValues(a,b){
    const obj = {}
    Object.keys(a).forEach(key => {
        if(b[key]){
            obj[key] = nested(a[key], b[key])
        }else {
            obj[key] = a[key]
        }
    })
    return obj
}

function nested(a, b){
    let obj = {}
    if(typeof a === 'object'){
        Object.keys(a).forEach(key => {
            if(b[key]){
                obj[key] = nested(a[key], b[key])
            }else {
                obj[key] = a[key]
            }
        })
    }else{
        obj = b
    }
    return obj
}