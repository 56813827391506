export const buttonConfirm = {
    root: ({ props, context }) => ({
        class: [
            { 'cursor-progress rounded-lg mr-2' : props.loading == true },
            { 'bg-green-500 rounded-lg mr-2' : context.disabled != true },
            { 'hover:bg-green-700 hover:ring-green-900 hover:translate-y-0.5'  : context.disabled != true },
            { 'cursor-not-allowed' : context.disabled == true},
            { 'bg-green-500/50 rounded-lg mr-2' : context.disabled == true},
            { 'p-4 scale-100' : props.label },
            { 'scale-75' : props.icon }
        ]
    }),
    icon: ({}) => ({
        class: [
            'm-4'
        ]
    }),
    loadingIcon: ({}) => ({
        class: [
            'mr-4'
        ]
    }),
    label: ({ props }) => ({
        class: [
            'flex-0',
            { 'hidden w-0 h-0': props.label == null }
        ]
    })
}

export const buttonStickToInput = {
    root: ({ props, context }) => ({
        class: [
            { 'bg-green-500 rounded-r-lg mr-2 h-9 p-2' : context.disabled != true },
            { 'hover:bg-green-700 hover:ring-green-900'  : context.disabled != true },
            { 'cursor-not-allowed' : context.disabled == true},
            { 'bg-green-500/50 rounded-r-lg mr-2 h-9 p-2' : context.disabled == true}
        ]
    }),
    icon: ({}) => ({
        class: [
            ''
        ]
    }),
    label: ({ props }) => ({
        class: [
            'flex-0',
            { 'hidden w-0 h-0': props.label == null }
        ]
    })
}

export const editButton = {
    root: ({ props, context }) => ({
        class: [
            { 'text-white bg-blue-500 rounded-lg mr-2 h-9 p-2': props.severity === 'success' },
            { 'hover:bg-blue-700 hover:ring-blue-900': props.severity === 'success' },
            { 'text-blue-600 bg-transparent ring-transparent': props.link && props.severity === 'info' },
            { 'text-red-600 bg-transparent ring-transparent': props.link && props.severity === 'danger' },
        ]
    })
}

export const logoutButton = {
    root: ({ props, context }) => ({
        class: [
            'bg-bleu/70 rounded-lg p-2 dark:bg-bleu',
            'hover:bg-bleu dark:hover:bg-bleu/50'
        ]
    })
}

export const ajouterBouton = {
    root: ({ props, context }) => ({
        class: [
            'bg-bleufonce/60 rounded-lg p-2 dark:bg-bleu',
            'hover:bg-bleufonce/30 dark:hover:bg-bleu/50'
        ]
    })
}

export const userEdit = {
    root: ({ props, context }) => ({
        class: [
            'bg-transparent text-bleu ring-1 ring-bleu rounded-full flex justify-center',
            'hover:bg-bleu/20 dark:hover:bg-bleu/30'
        ]
    }),
    icon: ({ props }) => ({
        class: [
            'p-3',
        ]
    }),
}

export const addServR = {
    root: ({ props, context }) => ({
        class: [
            'bg-transparent text-bleu ring-b-1 ring-bleu rounded-md justify-center p-2',
            'hover:underline dark:hover:underline'
        ]
    }),    
}

export const editGrants = {
    root: ({ props, context }) => ({
        class: [
            'rounded-lg p-2',
            {'bg-bleu/70 dark:bg-bleu' : context.disabled == false},
            {'hover:bg-bleu dark:hover:bg-bleu/50' : context.disabled == false},
            {'cursor-not-allowed bg-bleu/30' : context.disabled == true}
        ]
    })
}