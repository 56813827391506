import axios from "axios";
import TokenService from "../services/token.service";
import config from '@/config'

let headers = {
  "Content-Type": "application/json",
}
if (TokenService.getLocalRefreshToken() !== undefined) {
  headers["Authorization"]  = 'Bearer ' + TokenService.getLocalRefreshToken()
}
const instance = axios.create({
  baseURL: config['apiUrl'],
  headers: headers
});

export default instance;
