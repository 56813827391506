import { watch } from 'vue'
import store from '@/store/store'
import api from '../common/api'

var serviceSelected = store.getters['auth/getServiceSelected']

watch(() => store.getters['auth/getServiceSelected'], () => {
  serviceSelected = store.getters['auth/getServiceSelected']
})


class ServiceTicket {
    sendTicket(ticket) {
      return api.post(`/ticket/${serviceSelected}`, ticket);
    }
}
    
export default new ServiceTicket();
    