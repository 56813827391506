<template>
    <div v-if="!RELOADTIME" class="grid grid-cols-6 gap-5 my-5">
        <div class="relative dfcol gap-3">
            <div>
                <input v-model="filter" class="w-full p-1 bg-inherit text-black dark:text-white placeholder:italic border-b border-blanc outline-none" :placeholder="$t('icoaVue.grants.list')"/>
                <i class="pi pi-search absolute top-2.5 right-2.5"></i>
            </div>
            <!-- <div class="dfcol p-2 border-b border-blanc gap-2 cursor-default" >
                <p class="mr-auto">{{ $t('icoaVue.grants.list') }}</p>
            </div> -->
            <Listbox v-if="grants" v-model="selectedService" :options="filteredGrants" :pt="listBox.listBoxSimple" class="max-h-screen overflow-scroll">
                <template #empty>
                    <div class="ml-1">
                        {{ $t('icoaVue.grants.empty') }}
                    </div>
                </template>
            </Listbox>
            <div class="-ml-1">
                <Button v-if="serviceNot.length !== 0" label="Ajouter des droits serveur" severity="success" :pt="button.addServR" @click="visible = true"/>
            </div>
        </div>
        <Transition name="slide-fade">
            <Fieldset v-if="selectedService" :legend="selectedService" class="col-span-5">
                <div class="grid grid-cols-3 gap-3">
                    <div class="dfrow col-span-1 gap-2">
                        <p>{{ $t('icoaVue.grants.names.admin') }}</p>
                        <InputSwitch v-model="grants.grants[selectedService].admin"/>
                    </div>
                    <div class="grid grid-cols-2 col-span-2 gap-2">
                        <p class="col-span-1">{{ !switchAll ? 'Tout sélectionner' : 'Tout déselectionner' }}</p>
                        <InputSwitch v-model="switchAll" class="col-span-1" @change="onAll(selectedService)"/>
                    </div>
                </div>
                <Divider :pt="{root:{class:'border border-surface-300 dark:border-surface-700 my-5'}}"/>
                <div class="grid grid-cols-3 gap-2">
                    <div v-for="(value, key) in grants.grants[selectedService].grants" :key="key" class="col-span-1">
                        <p>{{$t(`icoaVue.grants.names.${key}`)}}</p>
                        <InputSwitch v-model="grants.grants[selectedService].grants[key]"/>                        
                    </div>
                </div>
                <div class="flex items-center gap-3 justify-end">
                    <Button :label="$t('icoaVue.grants.del')" text severity="danger" @click="visibleDel = true"/>
                    <Button :label="$t('icoaVue.grants.edit')" @click="updateGrants(selectedService)"/>
                </div>
            </Fieldset>
        </Transition>
    </div>

    <!-- AJOUT DE DROITS -->
    <Dialog v-model:visible="visible" modal :header="$t('icoaVue.grants.modalAdd.head')">
        <div class="px-1 pb-10">            
            <div class="relative grid grid-cols-4 gap-3">
                <div class="col-span-1">
                    <Listbox v-if="grants" v-model="newSelectedService" :options="serviceNot" :pt="listBox.listBoxSimple">
                        <template #empty>
                            <div class="ml-1">
                                {{ $t('icoaVue.grants.noother') }}
                            </div>
                        </template>
                    </Listbox>
                </div>
                <div class="col-span-3">
                    <Fieldset v-if="newSelectedService" :legend="newSelectedService" class="col-span-5">
                        <div class="grid grid-cols-3 gap-3">
                            <div class="dfrow col-span-1 gap-2">
                                <p>{{ $t('icoaVue.grants.names.admin') }}</p>
                                <InputSwitch v-model="grants.DEFAULT_GRANTS.admin"/>
                            </div>
                            <div class="grid grid-cols-2 col-span-2 gap-2">
                                <p class="col-span-1">{{ !switchAddAll ? 'Tout sélectionner' : 'Tout déselectionner' }}</p>
                                <InputSwitch v-model="switchAddAll" class="col-span-1" @change="changeAdd()"/>
                            </div>
                        </div>
                        <Divider :pt="{root:{class:'border border-surface-300 dark:border-surface-700 my-5'}}"/>
                        <div class="grid grid-cols-3 gap-2">
                            <div v-for="(value, key) in grants.DEFAULT_GRANTS.grants" :key="key" class="col-span-1">
                                <p>{{$t(`icoaVue.grants.names.${key}`)}}</p>
                                <InputSwitch v-model="grants.DEFAULT_GRANTS.grants[key]"/>
                            </div>
                        </div>
                        <div class="flex items-center gap-3 justify-end">                          
                            <Button :label="$t('icoaVue.grants.add')" @click="addGrants(newSelectedService ,grants.DEFAULT_GRANTS)"/>
                        </div>
                    </Fieldset>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="dfrow justify-between w-full">
                <Button :label="$t('icoaVue.grants.addAll')" @click="addAllServers()" text/>  
                <Button :label="$t('dt.cancel')" severity="info" text @click="visible = false" />
            </div>
        </template>
    </Dialog>

    <!-- SUPPRESSION DE DROITS -->
    <Dialog v-model:visible="visibleDel" modal :header="$t('icoaVue.grants.modalDel.head')">
        <div class="flex place-content-center my-10">
                {{ $t('icoaVue.grants.modalDel.text') + ' ' + selectedService + '?'}}
        </div>
        <template #footer>
            <Button :label="$t('dt.cancel')" severity="danger" @click="visibleDel = false" />
            <Button :label="$t('dt.delete')" severity="info" @click="deleteGrants(selectedService)"/>               
        </template>
    </Dialog>


</template>

<script setup>
import { computed, onBeforeMount, ref, toRaw, toRef, watch } from 'vue'
import { button, listBox } from '@/styles/over/styles'



const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    services: {
        type: Array,
        required: true
    },
})


const data = toRef(props, 'data')

const filter = ref('')
const filteredGrants = computed(() => {
    return Object.keys(grants.value.grants).filter(key => key.includes(filter.value))
})

const grants = ref(null)

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/icoadmin/grants')
    grants.value = new response.default(toRaw(data.value.grants))
}

const LISTE_SERVICE = toRef(props, 'services')

const serviceNot = computed(() => {
    const data = []
    LISTE_SERVICE.value.forEach(service => {
        if(!grants.value?.grants[service.ip]){
            data.push(service.ip)
        }
    })
    return data
})

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification ou ajout
 */
 const RELOADTIME = ref(false)

const visibleDel = ref(false)

const selectedService = ref(null)

const switchAll = ref(false)

function addGrants(service, newGrants){
    data.value.grants[service] = newGrants
    RELOADTIME.value = true
    grants.value.updateUser(data.value, data.value.id)
    .then(async() => {
        grants.value.getToast('info', '', grants.value.mToast.addGrants.success)
        await reloadTime()
        grants.value.resetGrants()
        newSelectedService.value = null
    })
    .catch(async() => {
        grants.value.getToast('error', '', grants.value.mToast.addGrants.fail)
        await reloadTime()
    })
}

async function addAllServers () {
    const adminGrants = toRaw(grants.value.DEFAULT_GRANTS)
    adminGrants.admin = true
    serviceNot.value.forEach(async(serv) => {
        data.value.grants[serv] = adminGrants
    })
    try {
        await grants.value.updateUser(data.value, data.value.id)
        grants.value.getToast('info', '', grants.value.mToast.addGrants.success)
        await reloadTime()
        grants.value.resetGrants()
        visible.value = false
    } catch (error) {
        grants.value.getToast('error', '', grants.value.mToast.addGrants.fail)
        await reloadTime()            
    } 
}

function updateGrants(service){
    data.value.grants[service] = grants.value.grants[service]
    RELOADTIME.value = true
    grants.value.updateUser(data.value, data.value.id)
    .then(async() => {
        grants.value.getToast('info', '', grants.value.mToast.editGrants.success)
        await reloadTime()
    })
    .catch(async() => {
        grants.value.getToast('error', '', grants.value.mToast.editGrants.fail)
        await reloadTime()
    })
}

function deleteGrants(service){
    selectedService.value = null
    delete data.value.grants[service]
    RELOADTIME.value = true
    grants.value.updateUser(data.value, data.value.id)
    .then(async() => {
        grants.value.getToast('info', '', grants.value.mToast.delGrants.success)
        await reloadTime()
        visibleDel.value = null
    })
    .catch(async() => {
        grants.value.getToast('error', '', grants.value.mToast.delGrants.fail)
        await reloadTime()
    })
}

async function reloadTime(){
    const response = await import('@/models/icoadmin/grants')
    grants.value = new response.default(toRaw(data.value.grants))
    RELOADTIME.value = false
}

function onAll(service){
    Object.keys(grants.value.grants[service].grants).forEach(key => {
        grants.value.grants[service].grants[key] = switchAll.value    
    })    
}

watch(selectedService, () => {
    switchAll.value = false
})
/* Logique de la popup ajout de droits */
const visible = ref(false)

const newSelectedService = ref(null)

const switchAddAll = ref(false)

function changeAdd(){
    Object.keys(grants.value.DEFAULT_GRANTS.grants).forEach(key => {
        grants.value.DEFAULT_GRANTS.grants[key] = switchAddAll.value    
    })
}

watch(newSelectedService, () => {
    grants.value.resetGrants()
    switchAddAll.value = false
})
/* ----------------------------------- */
</script>