const TRANSITIONS = {
    toggleable: {
        enterFromClass: 'max-h-0',
        enterActiveClass: 'overflow-hidden transition-all duration-500 ease-in-out',
        enterToClass: 'max-h-40	',
        leaveFromClass: 'max-h-40',
        leaveActiveClass: 'overflow-hidden transition-all duration-500 ease-in',
        leaveToClass: 'max-h-0'
    }
};

export const panelmenu = {
    root: 'min-w-fit mt-10',
    panel: 'mb-2',
    header: {
        class: [
            'outline-none',
        ]
    },
    headercontent: {
        class: [
            'text-dark-theme dark:text-white/80 rounded-md transition-shadow duration-200',
            'hover:bg-gray dark:hover:bg-gray hover:text-dark-theme dark:hover:text-white'
        ]
    },
    headeraction: {
        class: ['flex items-center select-none cursor-pointer relative no-underline', 'text-gray-700 dark:text-white/80 p-5 font-bold']
    },
    submenuicon: 'mr-2',
    headericon: 'mr-2',
    menu: {
        class: ['outline-none', 'm-0 p-0 list-none']
    },
    content: ({ context }) => ({
        class: [
            'text-gray-700 dark:text-white/80 transition-shadow duration-200 border-none rounded-none',
            'hover:bg-gray dark:hover:bg-gray  hover:text-dark-theme dark:hover:text-white', // Hover
            {
                'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused
            }
        ]
    }),
    action: {
        class: ['text-gray-700 dark:text-white/80 py-3 px-5 select-none', 'flex items-center cursor-pointer no-underline relative overflow-hidden']
    },
    icon: 'mr-2',
    submenu: 'p-0 pl-4 m-0 list-none',
    transition: TRANSITIONS.toggleable
}

export const panelmenuT = {
    root: 'min-w-fit mt-10',
    panel: 'mb-2',
    header: {
        class: [
            'outline-none',
        ]
    },
    headercontent: {
        class: [
            'text-dark-theme dark:text-white/80 rounded-md transition-shadow duration-200',
            'hover:bg-gray dark:hover:bg-gray hover:text-dark-theme dark:hover:text-white'
        ]
    },
    headeraction: {
        class: ['flex items-center select-none cursor-pointer relative no-underline', 'text-gray-700 dark:text-white/80 p-5 font-bold']
    },
    submenuicon: 'mr-2',
    headericon: 'mr-2',
    menu: {
        class: ['outline-none', 'm-0 p-0 list-none']
    },
    content: ({ context }) => ({
        class: [
            'text-gray-700 dark:text-white/80 transition-shadow duration-200 border-none rounded-none',
            'hover:bg-gray dark:hover:bg-gray  hover:text-dark-theme dark:hover:text-white', // Hover
            {
                'bg-red-300 text-red-700 dark:text-white/80 dark:bg-gray-800/90': context.focused
            }
        ]
    }),
    action: {
        class: ['text-gray-700 dark:text-white/80 py-3 px-5 select-none', 'flex items-center cursor-pointer no-underline relative overflow-hidden']
    },
    icon: 'mr-2',
    submenu: 'p-0 pl-4 m-0 list-none',
    transition: TRANSITIONS.toggleable
}
