<template>
    <div class="absolute top-0 left-0 w-full h-full backdrop-blur-sm rounded z-50">
        <span class="flex h-full justify-center items-center">
            <Image src="/tripode.png" alt="Image" width="50" class="animate-spin"/>
        </span>
    </div>
</template>

<script setup>

</script>