const ENV = process.env

let config = {}
const DEBUG = ENV.VUE_APP_DEBUG
const URL = ENV.VUE_APP_API_URL

const prod = {
    "apiUrl":"https://icoadminserveur.icodia.fr/api/",
    "apiMonitoring":"http://icomomo.com/"
}

const dev = {
    "apiUrl":URL,
    "apiMonitoring":"http://icomomo.com/"
}

export const version = 'v1.0.1'

if (DEBUG){
    config = dev;
}else{
    config = prod;
}

export default config
