class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refresh;
    }
  
    getLocalAccessToken() {
      const user = localStorage.getItem("user");
      if(user){
        return JSON.parse(user).access;
      }
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.access = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }
  
    setUser(user) {
      localStorage.setItem("user", JSON.stringify(user));
    }


    setInfos(info) {
      let user = JSON.parse(localStorage.getItem("user"))
      user.info = info
      localStorage.setItem("user", JSON.stringify(user))
    }
  
    removeUser() {
      localStorage.removeItem("user")
    }
  }
  
  export default new TokenService();
  