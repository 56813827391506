const initialState = {
    dropdowns: {},
    tabviews: {},
    inputs: {},
    datatables: {},
}

export const page = {
    namespaced: true,
    state: initialState,
    getters: {
        getDropdowns(state){
            return state.dropdowns
        },
        getTabviews(state){
            return state.tabviews
        },
        getInputs(state){
            return state.inputs
        },
        getDatatables(state){
            return state.datatables
        },
    },
    actions: {
        changeDropdowns({commit}, obj){
            commit('changeDropdowns', {value: obj.value, key: obj.key})
        },
        changeTabviews({commit}, obj){
            commit('changeTabviews', {value: obj.value, key: obj.key})
        },
        changeInputs({commit}, obj){
            commit('changeInputs', {value: obj.value, key: obj.key})
        },
        changeDatatables({commit}, obj){
            commit('changeDatatables', {value: obj.value, key: obj.key})
        },
    },
    mutations: {
        changeDropdowns(state, obj){
            state.dropdowns[obj.key] = obj.value
        },
        changeTabviews(state, obj){
            state.tabviews[obj.key] = obj.value
        },
        changeInputs(state, obj){
            state.inputs[obj.key] = obj.value
        },
        changeDatatables(state, obj){
            state.datatables[obj.key] = obj.value
        },     
    }
}

export default page