<template>
    
    <span v-if="typeVerif(type) === 1" class="truncate">{{ trueValue }}</span>
    <component v-else-if="typeVerif(type) === 2" :is="type" v-model="trueValue" :disabled="typeVerif(type) === 2 ? true : false" :binary="true"/>
    <div  v-else-if="typeVerif(type) === 3" class="dfrow flex-wrap gap-1 max-h-[88px] max-w-[300px] overflow-y-auto z-50">
        <Chip v-for="value in trueValue" v-clipboard="value">
            <span>{{ value }}</span>
        </Chip>
    </div>
    <component v-else-if="typeVerif(type) === 4" :is="type" :value="trueValue" :severity="options.severity(trueValue)"/>
</template>

<script setup>
import { computed } from 'vue';


const props = defineProps({
    value: {
        type: [String, Number, Boolean, Array],
        required: false
    },
    type: {
        type: String,
        default: 'InputText',
        required: true
    },
    options: {
        type: Object,
        default: null
    }
})

const trueValue = computed(() => {
    return props.value
})

function typeVerif(type){
    switch (type) {
        case 'Checkbox':
            return 2
        case 'Chip':
            return 3
        case 'Tag':
            return 4
        default:
            return 1
    }
}
</script>