<template>
    <div v-if="!serviceSelected" class="card flex flex-col basis-5/6 items-center justify-center px-8 py-8 md:h-screen lg:py-0 gap-5" >
        <h3 class="">Aucun de vos services n'est selectionné. Selectionnez un service pour accéder à sa gestion.</h3>
        <Dropdown v-model="serviceSelected" filter @change="changeServiceSelected($event)" :options="services" placeholder="Selectionnez un service" :optionDisabled="checkServ">
            <template #option="slotProps">
                <div>
                    {{ slotProps.option }}
                </div>
            </template>
        </Dropdown>
    </div>
    <div v-else-if="loading" class="flex flex-col items-center mt-10 md:h-screen lg:py-0 gap-5">
        <Loading :reload="reload"/>
    </div>
    <div v-else-if="serviceSelected && !loading">
        <Graphs/>
    </div>
</template>

<script setup>
// import PopupDialog from '@/components/Popup.vue'
// import { useDialog } from 'primevue/usedialog'
import { useToast } from 'primevue/usetoast'
import { nextTick, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ServicePing from '@/services/ping'
import Graphs from '@/pages/Graphs.vue'
import api from '@/common/api'

const toast = useToast()
const store = useStore()
const router = useRouter()
const serviceSelected = ref()
const reload = ref(false)
var user = null
var services = ref([])
const loading = ref(false)

onBeforeMount(() => {
    user = store.getters["auth/getUser"]
})

onMounted(async() => {
    serviceSelected.value = store.getters['auth/getServiceSelected']
    services.value = store.getters['auth/getServices']
    // AuthService.userInfos()
})

watch(() => store.getters['auth/getServices'], () => {
    services.value = store.getters['auth/getServices']
})

watch(() => store.getters['auth/getServiceSelected'], async() => {
    loading.value = true
    serviceSelected.value = store.getters['auth/getServiceSelected']
    await nextTick()
    loading.value = false
})

async function changeServiceSelected(event){
    loading.value = true
    reload.value = true
    ServicePing.ping(event.value)
    .then((response) => {
        store.dispatch('auth/changeServiceSelected', event.value).then(
            () => {
                toast.add({
                    summary: "Service modifié",
                    severity: "success",
                    detail: "Le service selectionné est "+event.value,
                    life: 4000
                })
            },
            _error => {
                // console.log(_error)
                toast.add({
                    summary: "Erreur",
                    severity: "error",
                    detail: "Impossible de modifier le service",
                    life: 4000
                })
            }
        )
        loading.value = false
        reload.value = false
        router.push('/')
    })
    .catch(() => {
        serviceSelected.value = null
        toast.add({
            summary: "Erreur",
            severity: "error",
            detail: "Impossible de modifier le service",
            life: 4000
        })
        loading.value = false
        reload.value = false
    })
}

</script>