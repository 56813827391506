export default {
    root: ({props:e,context:r}) => ({
        class: [
            "m-1",
            "hover:cursor-help",
            {
                "text-xs leading-[1.5rem]":e.size==null,
                "text-lg leading-[2.25rem]":e.size=="large",
                "text-2xl leading-[3rem]":e.size=="xlarge"
            },
            "text-center inline-block",
            "p-0 px-1",
            {
                "min-w-[1.5rem] h-[1.5rem]":e.size==null,
                "min-w-[2.25rem] h-[2.25rem]":e.size=="large",
                "min-w-[3rem] h-[3rem]":e.size=="xlarge"
            },
            {
                "rounded-full":e.value.length==1,
                "rounded-[0.71rem]":e.value.length!==1
            },
            "text-white dark:text-surface-900",
            {
                "bg-primary-500 hover:bg-primary-700 dark:bg-primary-400 dar:hover:bg-primary-600" : e.severity == null || e.severity == "primary",
                "bg-surface-500 dark:bg-surface-400":e.severity=="secondary",
                "bg-green-500 dark:bg-green-400":e.severity=="success",
                "bg-blue-500 dark:bg-blue-400":e.severity=="info",
                "bg-orange-500 dark:bg-orange-400":e.severity=="warning",
                "bg-purple-500 dark:bg-purple-400":e.severity=="help",
                "bg-red-500 dark:bg-red-400":e.severity=="danger"
            }
        ]
    })
}
