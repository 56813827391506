<template>
    <div v-if="(!LOADING && service) || RELOADTIME">
        <CustomDatatable :liste="LISTE" :columns="service.columns" @dataSelected="goToEdit" :dataSelected="inEditObj" :fields="service.fields.all" tName="icoServices">
            <template #headerAction>
                <Button :label="$t('dt.add')" text @click="visibleAdd = true"/>
            </template>
            <template #actionColumn>
                <Column headerStyle="min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex flex-row-reverse">
                            <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDelete(slotProps.data)"/>
                            <Button icon="pi pi-file-edit" text severity="info" v-tooltip="$t('dt.edit')" @click="goToEdit(slotProps.data)"/>
                        </div>
                    </template>
                </Column>
            </template>
        </CustomDatatable>
    </div>

    <div v-else class="flex flex-col items-center lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>
    <!-- CRÉER UN SERVCE  -->
    <Dialog v-model:visible="visibleAdd" modal :header="$t('icoaVue.addService')" @after-hide="resetFields">
        <Form
            @submit="(v) => addService(v)"
            id="formAdd"
            :validation-schema="service.schemas.all"
            @invalid-submit="(v) => console.log(v)"
            class="flex flex-col lg:flex-row pb-3 gap-3 justify-between"
        >
            <div class="dfcol gap-3 w-full">
                <Field :field="service.fields.all.ip"/>
                <Field :field="service.fields.all.servicetype_id"/>
                <Field :field="service.fields.all.name"/>
                <Field :field="service.fields.all.xor_key"/>
                <Field :field="service.fields.all.api_key"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAdd"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAdd = false"/>
        </template>        
    </Dialog>

    <!-- MODIFIER UN SERVICE -->
    <Dialog v-model:visible="visibleEdit" modal :header="$t('icoaVue.editService')" @after-hide="resetFields">
        <Form
            @submit="(v) => editService(v)"
            id="formEdit"
            :validation-schema="service.schemas.all"
            @invalid-submit="(v) => console.log(v)"
            class="dfrow pb-3 justify-between gap-3"
        >
            <div class="dfcol gap-3 w-full">
                <Field :field="service.fields.all.ip" :value="inEditObj.ip"/>
                <Field :field="service.fields.all.servicetype_id" :value="inEditObj.servicetype_id"/>
                <Field :field="service.fields.all.name" :value="inEditObj.name"/>
                <Field :field="service.fields.all.xor_key" :value="inEditObj.xor_key"/>
                <Field :field="service.fields.all.api_key" :value="inEditObj.api_key"/>

            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.edit')" severity="info" type="submit" form="formEdit"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleEdit = false"/>
        </template>        
    </Dialog>

    <!-- SUPPRIMER UN SERVCE -->
    <Dialog v-model:visible="visibleDelete" modal :header="$t('icoaVue.delService')">
        <div class="pb-3">
            <span>{{ $tic('icoaVue.confirmDelService', {service: inEditObj.ip}) }} </span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="delService"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDelete = false"/>
        </template>        
    </Dialog>
</template>

<script setup>
import { useStore } from 'vuex'
import { Form } from 'vee-validate'
import { nextTick, onBeforeMount, ref } from 'vue'
import Field from '@/components/NewField.vue'

const store = useStore()

let service = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/icoadmin/service')
    service = new response.default()
    await awaitAllOFThem()
}

/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
 const RELOADTIME = ref(false)

const LISTE = ref([])

async function getListe(){
    LOADING.value = service.servsText.load
    LISTE.value = await service.getListe()
    if(LISTE.value.constructor.name === 'Object'){
        LISTE.value = []
    }
}

async function getTypes(){
    const types = await service.getTypes()
    service.fields.all.servicetype_id.others.options = types
    LISTE.value.forEach(serv => {
        const type = types.find(x => x.id === serv.servicetype_id)
        serv.servicetype_name = type.servicetype
    })
}

async function awaitAllOFThem(){
    try {
        await getListe()
        await getTypes()
        LOADING.value = null
    } catch (error) {
        if (LOADING.value) {
            service.getToast('error', '', service.mToast.getServices.fail)
        }
    }
}
/* ------------------------------------- */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAdd = ref(false)
const visibleEdit = ref(false)
const visibleDelete = ref(false)

async function goToEdit(data){
    inEditObj.value = {...data}
    visibleEdit.value = true
}
function goToDelete(data){
    inEditObj.value = {...data}
    visibleDelete.value = true
}
function resetFields(){
    inEditObj.value = null
}
/* ------------------------------------------------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

function addService(values){
    service.createService(values)
    .then(async() => {
        service.getToast('info', '', service.mToast.addService.success)
        await reloadTime()
        visibleAdd.value = false
    })
    .catch(async() => {
        service.getToast('error', '', service.mToast.addService.fail)
        await reloadTime()
    })
}

function editService(values){
    const data = checkAll(values)
    service.updateService(data, data.id)
    .then(async() => {
        service.getToast('info', '', service.mToast.editService.success)
        await reloadTime()
        visibleEdit.value = false
    })
    .catch(async() => {
        service.getToast('error', '', service.mToast.editService.fail)
        await reloadTime()
    })
}

function delService(){
    service.deleteService(inEditObj.value.id)
    .then(async() => {
        service.getToast('info', '', service.mToast.delService.success)
        await reloadTime()
        visibleDelete.value = false
    })
    .catch(async() => {
        service.getToast('error', '', service.mToast.delService.fail)
        await reloadTime()
    })
}

function checkAll(values){
    const data = {...inEditObj.value}
    Object.keys(values).forEach(key => {
        if(values[key] !== data[key]){
            data[key] = values[key]
        }
    })
    return data    
}
async function reloadTime(){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
}
/* -------------------------------------------------------------- */
</script>