import api from '../common/api';

class ServicePing {
  
  ping(ip) {
    return api.get(`/ping/${ip}`);
  }

}
  
  export default new ServicePing();
  