import api from '../common/api';
import TokenService from "./token.service";
import router from '@/router/index';


class AuthService {
  login(user) {
    return api.post("/login/", {
      username: user.username,
      password: user.password
    })
    .then(response => {
      TokenService.setUser(response.data);
      return response.data;
    })
  }
  userInfos() {
    return api.get("/userinfo/")
    .then(response => {
      TokenService.setInfos(response.data)
      return response.data; 
    })
  }
  logout() {
    TokenService.removeUser();
    router.go('login');
  }
}

export default new AuthService();