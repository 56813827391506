import { createApp } from 'vue'
import 'primeicons/primeicons.css'
import './index.css'
import App from './App.vue'
import router from './router/index'
// import config from './config'
import store from './store/store'
import internationalization from '@/common/internationalization'
import setupInterceptors from './common/setupInterceptors'
import dynaToast from '@/common/dynaToast'

import StyleClass from 'primevue/styleclass'

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import DialogService from 'primevue/dialogservice'

import Loading from '@/assets/Loading.vue'
import ModalLoad from '@/components/ModalLoad.vue'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import AutoComplete from 'primevue/autocomplete'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import BlockUI from 'primevue/blockui'
import Breadcrumb from 'primevue/breadcrumb'
import Button from 'primevue/button'
import ButtonGroup from 'primevue/buttongroup'
import Calendar from 'primevue/calendar'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import DynamicDialog from 'primevue/dynamicdialog'
import Fieldset from 'primevue/fieldset'
import FocusTrap from 'primevue/focustrap'
import Image from 'primevue/image'
import InlineMessage from 'primevue/inlinemessage'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Knob from 'primevue/knob'
import Listbox from 'primevue/listbox'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MeterGroup from 'primevue/metergroup'
import OverlayPanel from 'primevue/overlaypanel'
import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import Ripple from 'primevue/ripple'
import ScrollTop from 'primevue/scrolltop'
import Sidebar from 'primevue/sidebar'
import Slider from 'primevue/slider'
import SpeedDial from 'primevue/speeddial'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import Tag from 'primevue/tag'
import Terminal from 'primevue/terminal'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip'
import TreeTable from 'primevue/treetable'
import Skeleton from 'primevue/skeleton'

import CustomDatatable from '@/components/datatable/Datatable.vue'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import Wind from '@/styles/base'

let app = createApp(App)
await mountApp()
async function mountApp(){
    app.component('Loading', Loading)
        .component('ModalLoad', ModalLoad)
        .component('Accordion', Accordion)
        .component('AccordionTab', AccordionTab)
        .component('AutoComplete', AutoComplete)
        .component('Badge', Badge)
        .component('BlockUI', BlockUI)
        .component('Breadcrumb', Breadcrumb)
        .component('Button', Button)
        .component('ButtonGroup', ButtonGroup)
        .component('Calendar', Calendar)
        .component('Chart', Chart)
        .component('Checkbox', Checkbox)
        .component('Chip', Chip)
        .component('Chips', Chips)
        .component('Column', Column)
        .component('ConfirmDialog', ConfirmDialog)
        .component('ConfirmPopup', ConfirmPopup)
        .component('DataTable', DataTable)
        .component('Dialog', Dialog)
        .component('Divider', Divider)
        .component('Dropdown', Dropdown)
        .component('DynamicDialog', DynamicDialog)
        .component('Fieldset', Fieldset)
        .component('Image', Image)
        .component('InlineMessage', InlineMessage)
        .component('InputGroup', InputGroup)
        .component('InputGroupAddon', InputGroupAddon)
        .component('InputNumber', InputNumber)
        .component('InputText', InputText)
        .component('InputSwitch', InputSwitch)
        .component('Knob', Knob)
        .component('Listbox', Listbox)
        .component('Menu', Menu)
        .component('Menubar', Menubar)
        .component('Message', Message)
        .component('MeterGroup', MeterGroup)
        .component('OverlayPanel', OverlayPanel)
        .component('PanelMenu', PanelMenu)
        .component('Password', Password)
        .component('ProgressBar', ProgressBar)
        .component('ProgressSpinner', ProgressSpinner)
        .component('ScrollTop', ScrollTop)
        .component('Sidebar', Sidebar)
        .component('Slider', Slider)
        .component('SpeedDial', SpeedDial)
        .component('Splitter', Splitter)
        .component('SplitterPanel', SplitterPanel)
        .component('TabPanel', TabPanel)
        .component('Tag', Tag)
        .component('Textarea', Textarea)
        .component('Terminal', Terminal)
        .component('ToggleButton', ToggleButton)
        .component('TabView', TabView)
        .component('Toast', Toast)
        .component('TreeTable', TreeTable)
        .component('Skeleton', Skeleton)
        .component('CustomDatatable', CustomDatatable)
    
    setupInterceptors(store)
    app.use(PrimeVue, {
        unstyled: true,
        ripple: true,
        pt: Wind
    })
    app.use(ConfirmationService)
    app.use(ToastService)
    app.use(DialogService)
    app.use(router)
    app.use(store)
    app.use(internationalization)
    app.use(dynaToast)
    app.use(VueClipboard)
    app.directive('styleclass', StyleClass)
    app.directive('tooltip', Tooltip)
    app.directive('badge', BadgeDirective)
    app.directive('ripple', Ripple)
    app.directive('focustrap', FocusTrap)
    app.mount('#app')
}


//Ne pas remplacer config.globalProperties sinon ça va effacer le globalProperties de base qui possède déjà des valeurs de base
// app.config.globalProperties.config = config
export const toast = app.config.globalProperties.$toast
export const TRAD = app.config.globalProperties.$t
export const TIC = app.config.globalProperties.$tic
export const DYNATOAST = app.config.globalProperties.$dynaToast

export default app
