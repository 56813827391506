<template>
    <div>
        <Terminal
            welcomeMessage="Bienvenue"
            prompt="$"
            aria-label="Terminal"
        />
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue';
import TerminalService from 'primevue/terminalservice'
import ServiceShell from '@/services/shell'

onMounted(() => {
    TerminalService.on('command', commandHandler);
})

onBeforeUnmount(() => {
    TerminalService.off('command', commandHandler);
})

const commandHandler = (txt) => {
    ServiceShell.getPrompt(txt)
    .then((response) => {
        TerminalService.emit('response', response.data.output);
    })
    .catch(() => {
        console.log('Impossible de converser avec le serveur')
    })
    // TerminalService.emit('response', txt + txt);
}


</script>