
<template>
    <div v-if="availableMonitoring && !loading.all">
        <div class="grid grid-flow-row auto-rows-max xl:grid-flow-col xl:auto-cols-auto gap-3">
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <h1>Charge moyenne</h1>
                <div class="dfrow items-center justify-center">
                    <Knob v-if="!loading.load" v-model="recentLoad.value" :min="0" :max="recentLoad.max" :size="200" readonly/>
                    <span v-else>Pas de données</span>
                </div>
            </div>
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <h1>Utilisation de la RAM</h1>
                <div class="dfrow items-center justify-center">
                    <Knob v-if="!loading.memory" v-model="recentMemory" :min="0" :max="100" :size="200" valueTemplate="{value}%" readonly/>
                    <span v-else>Pas de données</span>
                </div>
            </div>
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <h1>Utilisation du stockage</h1>
                <div class="dfrow items-center justify-center">
                    <Knob v-if="!loading.disk" v-model="recentDisk.value" :min="0" :max="100" :size="200" valueTemplate="{value}%" readonly/>
                    <span v-else>Pas de données</span>
                </div>
            </div>
        </div>
        <br>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3">
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir xl:col-span-1">
                <h1>Nombre de coeur</h1>
                <div class="dfrow justify-center">
                    <h1 v-if="!loading.cpu" class="text-2xl">{{ nbCpu }}</h1>
                    <span v-else>Pas de données</span>
                </div>
            </div>
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir xl:col-span-1">
                <h1>RAM</h1>
                <div class="dfrow justify-center">
                    <span v-if="!loading.memory" class="dfrow"><h1 class="text-2xl">{{ totalMemory }}</h1><h1 class="text-xs">GB</h1></span>
                    <span v-else>Pas de données</span>
                </div>
            </div>
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir xl:col-span-1">
                <h1>Espace disque restant</h1>
                <div class="dfrow justify-center">
                    <span v-if="!loading.disk" class="dfrow"><h1 class="text-2xl">{{ recentDisk.free }}</h1><h1 class="text-xs">GB</h1></span>
                    <span v-else>Pas de données</span>
                </div>
            </div>
            <div class="dfcol border-2 rounded p-4 shadow-xl max-h-min dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir xl:col-span-1">
                <h1>Espace disque total</h1>
                <div class="dfrow justify-center">
                    <span v-if="!loading.disk" class="dfrow"><h1 class="text-2xl">{{ recentDisk.total }}</h1><h1 class="text-xs">GB</h1></span>
                    <span v-else>Pas de données</span>
                </div>
            </div>
        </div>
        <br>
        <br>
        <div class="grid grid-flow-row auto-rows-max xl:grid-cols-2 gap-5">
            <div class="card flex flex-row xl:col-span-1 shadow-xl p-3 justify-center xl:min-h-[360px] rounded dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <Line v-if="!loading.cpu" :data="cpu.data" :options="cpu.options"/>
                <span v-else>Pas de données</span>
            </div>
            <div class="card flex flex-row xl:col-span-1 shadow-xl p-3 justify-center xl:min-h-[360px] rounded dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <Line v-if="!loading.memory" :data="memory.data" :options="memory.options"/>
                <span v-else>Pas de données</span>
            </div>
            <div class="card flex flex-row xl:col-span-1 shadow-xl p-3 justify-center xl:min-h-[360px] rounded dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <Line v-if="!loading.disk" :data="disk.data" :options="disk.options"/>
                <span v-else>Pas de données</span>
            </div>
            <div class="card flex flex-row xl:col-span-1 shadow-xl p-3 justify-center xl:min-h-[360px] rounded dark:bg-fond-dark dark:border-none dark:shadow-md dark:shadow-noir">
                <Line v-if="!loading.load" :data="load.data" :options="load.options"/>
                <span v-else>Pas de données</span>
            </div>
        </div>
    </div>
    <div v-else>
        Le monitoring n'est pas disponible sur ce serveur
    </div>
</template>

<script setup>
import { ref, onBeforeMount, computed, onUnmounted } from "vue"
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
import annotationPlugin from 'chartjs-plugin-annotation'
import ServiceGraph from '@/services/graph'
import { useStore } from "vuex";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    annotationPlugin,
    Legend
)

const store = useStore()
const dark = computed(() => {
    return store.getters['index/getDarkmode']
})
const loading = ref({
    cpu: false,
    disk: false,
    load: false,
    memory: false,
    all: false
})

const data = ref()
const availableMonitoring = ref(true)

const cpuGraphAxis = ref([0, 100])
const cpu = computed(() => {
    let labels = []
    let datarray = []
    let time
    data.value?.cpu?.cpuUsage?.forEach(element => {
        time = new Date(element.x)
        labels.push(returnTime(time))
        datarray.push(element.y)
    })
    const retour = {
        data: {
            labels: labels,
            datasets:[{
                label: 'Utilisation CPU',
                borderColor: '#f55d42',
                backgroundColor: 'rgb(245, 93, 66, 0.5)',
                tension: 0.4,
                fill: true,
                data: datarray
            }]
        },
        options: {
            responsive: true,
            scales: {
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: '%',
                        color: dark.value ? '#ffffff' : '#000000',
                        align: 'end',
                        padding: 0
                    },
                    min: 0,
                    max: 100
                }
            },
            plugins: {
                legend: {
                    position: 'bottom'
                }
            }
        }
    }
    return retour
})
const recentCpu = computed(() => {
    return data.value?.cpu?.cpuUsage?.at(-1).y.toFixed(2)
})
const nbCpu = computed(() => {
    return data.value?.cpu.cpuCoreNumber?.at(-1).y
})

const disk = computed(() => {
    let labels = []
    let dataFree = []
    let dataUsed = []
    let dataTotal = []
    let time
    const totalDisk = convert(data.value?.disk?.storageTotal?.at(-1).y, 3)
    data.value?.disk?.storageTotal?.forEach((element, index) => {
        time = new Date(element.x)
        labels.push(returnTime(time))
        const total = convert(element.y, 3)
        const free = convert(data.value?.disk.storageFree[index].y, 3)
        const used = convert(element.y, 3) - convert(data.value?.disk.storageFree[index].y, 3)
        dataTotal.push(total)
        dataFree.push(free)
        dataUsed.push(used)
    })
    const annotation1 = {
        type: 'line',
        borderColor: '#f55d42',
        borderWidth: 3,
        label: {
            backgroundColor: '#f55d42',
            content: Math.ceil(totalDisk),
            display: true
        },
        scaleID: 'y',
        value: totalDisk
    }
    const retour = {
        data: {
            labels: labels,
            datasets:[
                {
                    label: 'Stockage utilisé',
                    borderColor: '#4251f5',
                    backgroundColor: 'rgb(66, 81, 245, 0.5)',
                    tension: 0.4,
                    fill: true,
                    data: dataUsed                    
                },
            ]
        },
        options: {
            responsive: true,
            scales: {
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'GB',
                        color: dark.value ? '#ffffff' : '#000000',
                        align: 'end',
                        padding: 0
                    },
                    min: 0,
                    max: 1000
                }
            },
            plugins: {
                annotation: {
                    annotations: {
                        annotation1
                    }
                },
                legend: {
                    position: 'bottom'
                }
            }
        }     
    }
    return retour
})
const recentDisk = computed(() => {
    let labels = []
    const total = convert(data.value?.disk?.storageTotal?.at(-1).y, 3)
    const free = convert(data.value?.disk.storageFree?.at(-1).y, 3)
    const used = convert(data.value?.disk?.storageTotal?.at(-1).y, 3) - convert(data.value?.disk.storageFree?.at(-1).y, 3)
    const usedPt = (data.value?.disk?.storageTotal?.at(-1).y - data.value?.disk.storageFree?.at(-1).y) * 100 / data.value?.disk?.storageTotal?.at(-1).y
    const retour = {
        value: usedPt.toFixed(2),
        free: free.toFixed(2),
        total: total.toFixed(2)
    }
    return retour
})

const load = computed(() => {
    let labels = []
    let dataOneM = []
    let dataFiveM = []
    let dataFifteenM = []
    let time
    let max = 20
    data.value?.load['1m']?.forEach((element, index) => {
        time = new Date(element.x)
        labels.push(returnTime(time))
        const one = element.y
        const five = data.value?.load['5m'][index]?.y
        const fifteen = data.value?.load['15m'][index].y
        if(one > 20 || five > 20 || fifteen > 20){
            max = Math.max(one, five, fifteen)
        }
        dataOneM.push(one)
        dataFiveM.push(five)
        dataFifteenM.push(fifteen)
    })
    const retour = {
        data: {
            labels: labels,
            datasets:[
                {
                    label: 'Charge sur moyenne sur 1m',
                    borderColor: '#4251f5',
                    backgroundColor: 'rgb(66, 81, 245, 0.2)',
                    tension: 0.4,
                    fill: true,
                    data: dataOneM
                },
                {
                    label: 'Charge sur moyenne sur 5m',
                    borderColor: '#f55d42',
                    backgroundColor: 'rgb(245, 93, 66, 0.5)',
                    tension: 0.4,
                    fill: true,
                    data: dataFiveM                    
                },
                {
                    label: 'Charge sur moyenne sur 15m',
                    borderColor: '#32a852',
                    backgroundColor: 'rgb(50, 168, 82, 0.5)',
                    tension: 0.4,
                    fill: true,
                    data: dataFifteenM                    
                },
            ]
        },
        options: {
            responsive: true,
            scales: {
                y: {
                    display: true,
                    min: 0,
                    max: max > 20 ? max + 1 : max 
                }
            },
            plugins: {
                legend: {
                    position: 'bottom'
                }
            }
        }    
    }
    return retour
})
const recentLoad = computed(() => {
    let max = 20
    let dataFiveM = data.value?.load['5m']?.at(-1).y
    if(dataFiveM > 20){
        max = Math.max(dataFiveM)
    }
    return {
        value: dataFiveM.toFixed(2),
        max: max,
    }
})

const memory = computed(() => {
    let labels = []
    let dataFree = []
    let dataUsed = []
    let dataTotal = []
    let time
    const totalDisk = convert(data.value?.memory?.memoryTotal?.at(-1).y, 2)
    data.value?.memory?.memoryTotal?.forEach((element, index) => {
        time = new Date(element.x)
        labels.push(returnTime(time))
        const total = convert(element.y, 2)
        const used = data.value?.memory.memoryUsagePerc[index].y
        const free = element.y - used
        dataTotal.push(total)
        dataFree.push(convert(free, 2))
        dataUsed.push(used)
    })
    const retour = {
        last:{
            totalDisk: totalDisk
        },
        data: {
            labels: labels,
            datasets:[
                {
                    label: 'Mémoire utilisé',
                    borderColor: '#4251f5',
                    backgroundColor: 'rgb(66, 81, 245, 0.5)',
                    tension: 0.4,
                    fill: true,
                    data: dataUsed                    
                },
            ]
        },
        options: {
            responsive: true,
            scales: {
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: '%',
                        color: dark.value ? '#ffffff' : '#000000',
                        align: 'end',
                        padding: 0
                    },
                    min: 0,
                    max: 100
                }
            },
            plugins: {
                legend: {
                    position: 'bottom'
                }
            }
        }     
    }
    return retour
})
const recentMemory = computed(() => {
    let labels = []
    const retour = (data.value?.memory?.memoryUsagePerc?.at(-1).y).toFixed(2)
    return retour
})
const totalMemory = computed(() => {
    return Math.ceil(convert(data.value?.memory?.memoryTotal?.at(-1).y, 2))
})

onBeforeMount(() => {
    getDataGraph(true)
})

const getDataGraph = (bool) => {
    if(bool){
        loading.value.all = true
    }
    ServiceGraph.getData()
    .then((response) => {
        data.value = response.data
        // console.log('Données récupérées')
        Object.keys(data.value).every(key => {
            if(Object.keys(data.value[key]).length === 0){
                // availableMonitoring.value = false
                loading.value[key] = true
                return false
            }else {
                loading.value[key] = false                
            }
            return true
        })
        loading.value.all = false
    })
    .catch((e) => {
        console.log('Erreur')
    })
    .finally(() => {
    })
}

const interval = setInterval(() => {
    // getDataGraph(false)
}, 30000);

function convert(value, times){
    return value / (Math.pow(1024, times))
}

function returnTime(time){
    const hour = time.getHours() < 10 ? '0'+time.getHours() : time.getHours()
    const minutes = time.getMinutes() < 10 ? '0'+time.getMinutes() : time.getMinutes()
    const seconds = time.getSeconds() < 10 ? '0'+time.getSeconds() : time.getSeconds()
    return hour + ':' + minutes + ':' + seconds
}

onUnmounted(() => {
    clearInterval(interval)
})
</script>
