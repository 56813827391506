import { watch } from 'vue';
import api from '../common/api';
import store from '@/store/store'

var serviceSelected = store.getters['auth/getServiceSelected']
var userInfo = null
if(store.getters['auth/getUser']){
  userInfo = store.getters['auth/getUser']['info']['grants']
}

watch(() => store.getters['auth/getServiceSelected'], () => {
  serviceSelected = store.getters['auth/getServiceSelected']
  // console.log(userInfo[serviceSelected]["token_monitoring"])
})

class ServiceGraph {
    getData() {
      return api.post(`monitor/${serviceSelected}`,{'token':userInfo? userInfo[serviceSelected]["token_monitoring"] : null})
    }
}

export default new ServiceGraph()