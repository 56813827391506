<template>
    <span>{{ $tic('icoaVue.lastCheck', {date: LAST_CHECK}) }}</span>
    <div v-if="(!LOADING && servicestate)">
        <CustomDatatable :liste="LISTE" :columns="servicestate.columns" :fields="servicestate.fields.all" tName="icoServices" :adFilter="['state', 'ip']">
        </CustomDatatable>
    </div>

    <div v-else class="flex flex-col items-center lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'

let servicestate = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/icoadmin/servicestate')
    servicestate = new response.default()
    await awaitAllOFThem()
}


/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)

const LISTE = ref([])

const LAST_CHECK = ref(null)

async function getListe(){
    LOADING.value = servicestate.servStateText.load
    const response = await servicestate.getListe()
    if(response.constructor.name === 'Object'){
        LISTE.value = []
    }
    LAST_CHECK.value = Object.keys(response)[0]
    const last = Object.entries(response)[0]
    const severityValue = servicestate.columns.find(x => x.field === 'state')
    Object.entries(last[1]).forEach(service => {
        LISTE.value.push({
            ip: service[0],
            state: severityValue.filter.value(service[1])
        })
    })
}

async function awaitAllOFThem(){
    try {
        await getListe()
        LOADING.value = null
    } catch (error) {
        if (LOADING.value) {
            servicestate.getToast('error', '', servicestate.mToast.getListe.fail)
        }
    }
}
/* ------------------------------------- */
</script>