<template>
    <Button icon="pi pi-user-edit" outlined :pt="button.userEdit" @click="visible = true" v-tooltip="$t('user.btnModal')"/>
    <Dialog v-model:visible="visible" modal :header="$t('user.modalHead')" @after-hide="resetFields">
        <div v-if="!RELOADTIME" class="dfrow justify-between pb-5">
            <Form
                @submit="(v) => changeMail(v)"
                id="changeMail"
                :validation-schema="user.schemas.changeMail"
                @invalid-submit="(v) => console.log(v)"
                class="dfcol gap-3"
            >
                <div class="dfcol gap-3">
                    <Field :field="user.fields.email" :value="email"/>
                    <Field :field="user.fields.emailCheck" :value="emailCheck"/>
                </div>
                <div class="w-full flex flex-row-reverse">
                    <Button :label="$t('user.mailEdit')" severity="info" type="submit" form="changeMail"/>
                </div>
            </Form>
            <Form
                @submit="(v) => changePass(v)"
                id="changePass"
                :validation-schema="user.schemas.changePass"
                @invalid-submit="(v) => console.log(v)"
                class="dfcol gap-3"
            >
                <div class="dfcol gap-3">
                    <Field :field="user.fields.password" :value="password" @updateValue="(v, gen) => passGen(v, gen)"/>
                    <Field :field="user.fields.passwordCheck" :value="passwordCheck"/>
                </div>
                <div class="w-full flex flex-row-reverse">
                    <Button :label="$t('user.passEdit')" severity="info" type="submit" form="changePass"/>
                </div>
            </Form>
        </div>
        <ModalLoad v-else/>
        <template #footer>
            <Button :label="$t('dt.modal.close')" text severity="info" @click="visible = false"/>
        </template>   
    </Dialog>
</template>

<script setup>
import { nextTick, onBeforeMount, ref } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'
import { button } from '@/styles/over/styles.js'

let user = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/logUser')
    user = new response.default()
}


const visible = ref(false)

const changeMail = (values) => {
    RELOADTIME.value = true
    user.changeMail(values)
    .then(async() => {
        user.getToast('info', '', user.mToast.mail.success)
        await itsRelaodTime()
    })
    .catch(async() => {
        user.getToast('error', '', user.mToast.mail.fail)
    })
}
const changePass = (values) => {
    RELOADTIME.value = true
    user.changePass(values)
    .then(async() => {
        user.getToast('info', '', user.mToast.pass.success)
        await itsRelaodTime()
    })
    .catch(async() => {
        user.getToast('error', '', user.mToast.pass.fail)
    })
}

const itsRelaodTime = async() => {
    resetFields()
    await nextTick()
    RELOADTIME.value = false
}

const RELOADTIME = ref(false)

/* POUR LES CHAMPS PASSWORDS */
const password = ref(null)
const passwordCheck = ref(null)

const email = ref(null)
const emailCheck = ref(null)

function passGen(pass, gen){
    password.value = pass
    if(gen){
        passwordCheck.value = pass
    }
}

function resetFields(){
    password.value = null
    passwordCheck.value = null
    email.value = null
    emailCheck.value = null
}
/* ------------------------- */
</script>