<template>
    <div class="card flex flex-col items-center justify-center px-8 py-8 md:h-screen lg:py-0 gap-5 ">
        <div class="bg-bleu/30 p-9 rounded-xl backdrop-blur-xl flex flex-col gap-6 shadow-xl">
            <div class="text-center">
                <span class="inline-flex justify-center items-center p-4">
                    <Image src="/Logo2-transp.png" alt="Image" width="100"/>
                </span>
            </div>
            <form @submit.prevent="onSubmit" class="flex flex-col gap-2">
                <span>
                    <InputText  
                        id="username"
                        v-model="username" type="text"
                        v-bind="usernameAttrs"
                        :class="{ 'p-invalid': errors.username }" 
                        aria-describedby="text-error" 
                        placeholder="Nom d'utilisateur" 
                        :pt="inputs.inputTextLogin"
                    />
                    <!-- <Badge value="?" v-tooltip="'Entrez votre nom d\'utilisateur'"></Badge> -->
                </span>
                <InlineMessage v-if="errors.username" class="p-error" id="text-error" severity="error">{{ errors.username }}</InlineMessage>
                <span>
                    <InputText  
                        id="password" 
                        v-model="password" type="password"
                        v-bind="passwordAttrs"
                        :class="{ 'p-invalid': errors.password }" 
                        aria-describedby="password-error" 
                        placeholder="Password" 
                        :pt="inputs.inputTextLogin"
                    />
                </span>
                <InlineMessage v-if="errors.password" class="p-error" id="text-error" severity="error">{{ errors.password }}</InlineMessage>
                <Button :label="'Login'" type="submit"/>
            </form>
        </div>
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import User from '@/models/user'
import { inputs } from '@/styles/over/styles'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { useToast } from "primevue/usetoast"
import * as yup from 'yup'

import AuthService from '@/services/auth.service'
import TokenService from '@/services/token.service';

const user = new User('', '')
const app = getCurrentInstance()
const router = useRouter()
const store = useStore()

const { errors, handleSubmit, defineField, resetForm } = useForm({
    validationSchema: yup.object({
        username: yup.string().required('Le nom d\'utilisateur est requis'),
        password: yup.string().required('Mot de passe requis'),
    })
})

const [username, usernameAttrs] = defineField('username')
const [password, passwordAttrs] = defineField('password')

const toast = useToast()
const onSubmit = handleSubmit(onSuccess, onInvalidSubmit)

const userStorage = computed (()=> store.getters['auth/getUser'] )


function onSuccess(values) {
    user.username = values.username
    user.password = values.password
    if (user.username && user.password) {
        AuthService.login(user).then(
            user => {
                store.dispatch('auth/login', user);
                AuthService.userInfos().then(
                    response => {
                        TokenService.setInfos(response);
                        store.dispatch('auth/userInfo', response);
                        if(Object.keys(response.grants).length === 1){
                            store.dispatch('auth/changeServiceSelected', Object.keys(response.grants)[0])
                        }
                    }
                )
                .catch((e) => {
                    AuthService.logout();
                    store.dispatch('auth/logout');
                })
                return Promise.resolve(user);
            }
        ).catch(
            (error)=> {
                console.log("NOT LOG !")

                toast.add({ 
                    severity: 'error', 
                    summary: 'Connexion impossible', 
                    detail: 'Identifiants incorrect, merci de contacter le support si vous souhaitez restaurer les identifiants', 
                    life: 4000 
                });
                store.dispatch('auth/loginFailure');
                return Promise.reject(error);
            }
        )
        .finally(
            () => {
                return router.push('/');
            })
    }
}

function onInvalidSubmit({values, errors, results}){
    toast.add({ severity: 'error', summary: 'Erreur', detail: 'Entrées invalides', life: 3000 })
}
onMounted(()=>{
    if (userStorage != null) {
        router.push('/');
    }
})
</script>
