<template>
    <h1 class="mb-5">
        Gestion Cron
    </h1>
    <div v-if="LOADING === 'ERROR'">
        <span>{{ $t('dt.unavailable') }}</span>
    </div>
    <div v-else-if="(!LOADING && cron) || RELOADTIME" class="mt-12">
        <CustomDatatable :liste="LISTE" :columns="cron.columns" :fields="cron.fields" tName="cron">
            <template #headerAction>
                <Button :label="$t('dt.add')" text @click="visibleAdd = true"/>
            </template>
            <template #actionColumn>
                <Column headerStyle="min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex flex-row-reverse">
                            <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDelete(slotProps.data)"/>
                        </div>
                    </template>
                </Column>
            </template>
        </CustomDatatable>
    </div>
    <div v-else class="flex flex-col items-center md:h-screen lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>

    <Dialog v-model:visible="visibleAdd" modal :header="$t('cronVue.addCron')">
        <Form
            @submit="(v) => addCron(v)"
            id="formAdd"
            :validation-schema="cron.schemas.add"
            @invalid-submit="(v) => console.log(v)"
            class="grid grid-cols-2 gap-3 pb-3"
        >
            <Field :field="cron.fields.name"/>
            <div class="dfcol gap-1">
                <label for="command">{{ $t('cronVue.commandLabel') }}</label>
                <div class="dfrow items-center gap-1">
                    <Dropdown v-model="commandType" :options="commandTypes" option-label="label" option-value="value" name="typeOfCommand" style="min-width: fit-content; height: 38px;"/>
                    <span class="pi pi-question border rounded-full mt-0.5 p-0.5 h-fit hover:cursor-pointer w-fit" style="font-size: 10px" v-tooltip="$t('cronVue.helperType')"></span>
                </div>
                <Field v-if="!changing" :field="cron.fields.command"/>
            </div>
            <Field :field="cron.fields.user"/>
            <Field :field="cron.fields.frequency"/>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAdd"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAdd = false"/>
        </template>
    </Dialog>
    <Dialog v-model:visible="visibleDelete" modal :header="$t('cronVue.addCron')">
        <div class="pb-3">
            <span>{{ $t('cronVue.confirmDeleteText') }} {{ inEditObj.name }}</span>
        </div>    
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="confirmedDelete()"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDelete = false"/>
        </template>        
    </Dialog>
</template>

<script setup>
import { nextTick, onBeforeMount, ref, watch } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'
import { TRAD } from '@/main'

let cron = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/cron')
    cron = new response.default()
    await awaitAllOFThem()
}

/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)

/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
 const RELOADTIME = ref(false)

const LISTE = ref([])

async function getListe(){
    LOADING.value = cron.cronText.load.crons
    LISTE.value = await cron.getListe() 
    // RELOADTIME.value ? null : cron.getToast('info', '', cron.mToast.getCrons.success)
}

async function awaitAllOFThem(){
    try {
        await getListe()
        LOADING.value = null
    } catch (error) {
        if (LOADING.value) {
            cron.getToast('error', '', cron.mToast.getCrons.fail)
        }
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAdd = ref(false)
const visibleDelete = ref(false)

function goToDelete(data){
    visibleDelete.value = true
    inEditObj.value = {...data}
}
/* ------------------------------------------------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})
const commandType = ref('command')
const commandTypes = ref(Object.values(TRAD('cronVue.commandTypes')))
const changing = ref(false)

watch(commandType, async() => {
    changing.value = true
    cron.fields.command.placeholder = TRAD(`cronVue.commandPlaceholder.${commandType.value}`)
    await nextTick()
    changing.value = false
})

async function addCron(values){
    const splitted = values.frequency.value.split(" ")
    const user = values.user.value
    commandType.value === 'url' ? values.command = `/usr/bin/wget --quiet -O /dev/null --no-check-certificate "${values.command}"` : null
    values.user = user
    values.minute = splitted[0]
    values.hour = splitted[1]
    values.dayweek = splitted[2]
    values.daymonth = splitted[3]
    values.month = splitted[4]
    delete values.frequency
    RELOADTIME.value = true
    try {
        console.log(values)
        await cron.addCron(values)
        await reloadTime()
        visibleAdd.value = false
        commandType.value = 'command'
        cron.getToast('info', '', cron.mToast.addCron.success)
    } catch (e) {
        cron.getToast('error', '', cron.mToast.addCron.fail)
    }
}

function confirmedDelete(){
    RELOADTIME.value = true
    cron.deleteCron(inEditObj.value)
    .then(async(response) => {
        cron.getToast('info', '', cron.mToast.delCron.success)
        await reloadTime()
        visibleDelete.value = false
    })
    .catch(async(e) => {
        cron.getToast('error', '', cron.mToast.delCron.fail)
        await reloadTime()
    })
}

async function reloadTime(){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
}
/* -------------------------------------------------------------- */

</script>
