<template>
    <div v-if="LOADING === 'ERROR'">
        <span>{{ $t('dt.unavailable') }}</span>
    </div>
    <div v-else-if="(!LOADING && ra) || RELOADTIME" class="mt-12 dfcol gap-5">
        <div v-if="Object.keys(LISTE).length === 0">
            {{ $t('raVue.noGroup') }}
        </div>
        <div v-else v-for="group in Object.keys(LISTE)">
            <h1 class="mb-5">
                {{ $t('raVue.group') + ' ' + group }}
            </h1>        
            <CustomDatatable :liste="LISTE[group]" :columns="ra.columns" :fields="ra.fields" export :tName="`ra.${group}`">
                <template #headerAction>
                    <Button :label="$t('dt.add')" text @click="goToAdd(group)"/>
                </template>
                <template #actionColumn>
                    <Column headerStyle="min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex flex-row-reverse">
                                <Button icon="pi pi-trash" text severity="danger" v-tooltip="$t('dt.delete')" @click="goToDelete(slotProps.data, group)"/>
                            </div>
                        </template>
                    </Column>
                </template>
            </CustomDatatable>
        </div>
    </div>
    <div v-else class="flex flex-col items-center md:h-screen lg:py-0 gap-5">
        <Loading :message="LOADING"/>
    </div>

    <!-- AJOUTER UN ACCÈS DISTANT -->
    <Dialog v-model:visible="visibleAdd" modal :header="$t('raVue.addRa') + ' ' + onGoGroup">
        <Form
            @submit="(v) => addRa(v)"
            id="formAdd"
            :validation-schema="ra.schemas.add"
            @invalid-submit="(v) => console.log(v)"
            class="dfrow pb-3 justify-between"
        >
            <div class="dfcol gap-3">
                <Field :field="ra.fields.remoteip"/>
            </div>
        </Form>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.add')" severity="info" type="submit" form="formAdd"/>
            <Button :label="$t('dt.cancel')" text severity="danger" @click="visibleAdd = false"/>
        </template>        
     </Dialog>
    <!-- SUPPRIMER UN ACCÈS DISTANT -->
     <Dialog v-model:visible="visibleDelete" modal :header="$t('raVue.deleteRa')">
        <div class="pb-3">
            <span>{{ $t('raVue.confirmDeleteText') }} {{ inEditObj.remoteip }}</span>
        </div>
        <ModalLoad v-if="RELOADTIME"/>
        <template #footer>
            <Button :label="$t('dt.delete')" severity="danger" @click="deleteRa"/>
            <Button :label="$t('dt.cancel')" text severity="info" @click="visibleDelete = false"/>
        </template>        
     </Dialog>
</template>

<script setup>
import { nextTick, onBeforeMount, ref } from 'vue'
import { Form } from 'vee-validate'
import Field from '@/components/NewField.vue'

let ra = null

onBeforeMount(async() => {
    await importedFiles()
})

async function importedFiles(){
    const response = await import('@/models/remoteaccess')
    ra = new response.default()
    await awaitAllOFThem()
}


/* Récupération des informations en base avec un chargement*/
/**
 * @const LOADING va faire 2 choses : permettre l'affichage de la table ou du message de chargement ET ÊTRE le message de chargement
 */
 const LOADING = ref(null)
/**
 * @const RELOADTIME est utilisé lorsque des changements surviennent durant la modification
 *                   permettant de recharger les infos sans fermer la POPUP/MODAL et sans que le LOADING ne s'affiche
 */
const RELOADTIME = ref(false)

const LISTE = ref({})

async function getListe(){
    LOADING.value = ra.raText.load.ras
    const res = await ra.getListe()
    // ra.getToast('info', '', ra.mToast.getRa.success)
    Object.keys(res).forEach(group => {
        LISTE.value[group] = []
        res[group].forEach(remoteip => {
            LISTE.value[group].push({remoteip: remoteip})
        })
    })
}

async function awaitAllOFThem(){
    try {
        await getListe()
        LOADING.value = null
    } catch (error) {
        if (LOADING.value) {
            ra.getToast('error', '', ra.mToast.getRa.fail)
        }
        LOADING.value = 'ERROR'
    }
}
/* ------------------------------------- */

/* Variables et logique des POPUPS/MODALS qui s'affichent lors de clic sur bouton */
const visibleAdd = ref(false)
const visibleDelete = ref(false)

const onGoGroup = ref(null)

function goToAdd(group){
    onGoGroup.value = group
    visibleAdd.value = true
}

function goToDelete(data, group){
    onGoGroup.value = group
    inEditObj.value = {...data}
    visibleDelete.value = true
}
/* ------------------------------------------------------------------------------ */

/* Logique de données en cours d'édition, d'ajout, de suppression */
const inEditObj = ref({})

function addRa(values){
    const data = {
        remoteip: values.remoteip,
        group: onGoGroup.value
    }
    RELOADTIME.value = true
    ra.addRemoteAccess(data)
    .then(() => {
        ra.getToast('info', '', ra.mToast.addRa.success)
    })
    .catch(() => {
        ra.getToast('error', '', ra.mToast.addRa.fail)
    })
    .finally(async() => {
        await reloadTime()
        visibleAdd.value = false
    })
}
function deleteRa(){
    const data = {
        remoteip: inEditObj.value.remoteip,
        group: onGoGroup.value
    }
    RELOADTIME.value = true
    ra.deleteRemoteAccess(data)
    .then(() => {
        ra.getToast('info', '', ra.mToast.delRa.success)
    })
    .catch(() => {
        ra.getToast('error', '', ra.mToast.delRa.fail)
    })
    .finally(async() => {
        await reloadTime()
        visibleDelete.value = false
    })
}
async function reloadTime(){
    await awaitAllOFThem()
    await nextTick()
    RELOADTIME.value = false
}
/* -------------------------------------------------------------- */
</script>
